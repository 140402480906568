import { gql } from '@apollo/client';

const DELETE_DASHBOARD_CONFIG = gql`
    mutation deleteDashboardConfig($dashboardConfig: DashboardConfigInput!) {
        deleteDashboardConfig(dashboardConfig: $dashboardConfig) {
            ok
        }
    }
`;

export default DELETE_DASHBOARD_CONFIG;
