import { DASHBOARDS, LAST_ACTIVE_DASHBOARD_TAB } from '../../constants/localStorageConstants';
import { DashboardConfig } from '../../types/dashboardConfig';

export interface LocalStorageDashboardConfig {
    title: string;
    id: string;
    config: DashboardConfig;
}

export type DashboardsData = Record<string, LocalStorageDashboardConfig[]>;

export const deleteDashboardFromLocalStorage = (userId: string, dashboardId: string) => {
    try {
        let dashboards = localStorage.getItem(DASHBOARDS);

        let dashboardsData: DashboardsData = {};

        if (dashboards) {
            dashboardsData = JSON.parse(dashboards);

            if (dashboardsData[userId] && Array.isArray(dashboardsData[userId])) {
                const index = dashboardsData[userId].findIndex(({ id }) => id === dashboardId);
                if (index !== -1) {
                    dashboardsData[userId].splice(index);
                    localStorage.setItem(DASHBOARDS, JSON.stringify(dashboardsData));
                }
            }
        }
    } catch {
        return;
    }
};

export const getDashboardsFromLocalStorage = (userId: string) => {
    try {
        let dashboards = localStorage.getItem(DASHBOARDS);

        let dashboardsData: DashboardsData = {};
        if (dashboards) {
            dashboardsData = JSON.parse(dashboards);
        }

        return Array.isArray(dashboardsData[userId]) && dashboardsData[userId] ? dashboardsData[userId] : [];
    } catch {
        return [];
    }
};

export const saveDashboardsToLocalStorage = (userId: string, newDashboards: LocalStorageDashboardConfig[]) => {
    try {
        let dashboards = localStorage.getItem(DASHBOARDS);

        let dashboardsData: DashboardsData = {};
        if (dashboards) {
            dashboardsData = JSON.parse(dashboards);
        }

        dashboardsData[userId] = newDashboards;
        localStorage.setItem(DASHBOARDS, JSON.stringify(dashboardsData));
    } catch {
        return;
    }
};

export const saveDashboardToLocalStorage = (
    userId: string,
    dashboardId: string,
    title: string,
    newDashboardConfig: DashboardConfig
) => {
    try {
        let dashboards = localStorage.getItem(DASHBOARDS);

        let dashboardsData: DashboardsData = {};
        if (dashboards) {
            dashboardsData = JSON.parse(dashboards);
        }

        if (!dashboardsData[userId]) {
            dashboardsData[userId] = [];
        } else if (!Array.isArray(dashboardsData[userId])) {
            dashboardsData[userId] = [];
        }

        const index = dashboardsData[userId].findIndex(({ id }) => id === dashboardId);
        const dashboardData = { id: dashboardId, title, config: newDashboardConfig };
        if (index !== -1) {
            dashboardsData[userId][index] = dashboardData;
        } else {
            dashboardsData[userId].push(dashboardData);
        }
        localStorage.setItem(DASHBOARDS, JSON.stringify(dashboardsData));
    } catch {
        return;
    }
};

export const parseDashboardConfig = (config: string): DashboardConfig | undefined => {
    try {
        return JSON.parse(config) as DashboardConfig;
    } catch {
        return;
    }
};

export const getDashboardDataFromLocalStorage = (
    id: string,
    userId: string
): LocalStorageDashboardConfig | undefined => {
    try {
        const dashboards = localStorage.getItem(DASHBOARDS);
        if (!dashboards) {
            return;
        }
        const dashboardsData = JSON.parse(dashboards) as DashboardsData;
        if (!dashboardsData[userId] || !Array.isArray(dashboardsData[userId])) {
            return;
        }

        return dashboardsData[userId].find((item) => item.id === id);
    } catch {
        return;
    }
};

export const getLastOpenedDashboardTab = () => {
    let tabIndex = 0;
    const tab = localStorage.getItem(LAST_ACTIVE_DASHBOARD_TAB);
    if (tab) {
        const tabNumber = Number(tab);
        if (!isNaN(tabNumber)) {
            tabIndex = tabNumber;
        }
    }

    return tabIndex;
};
