import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import AreaStackPreview from '../../static/img/AreaStack.svg';
import BarPreview from '../../static/img/Bar.svg';
import BarStackPreview from '../../static/img/BarStack.svg';
import BarGroupPreview from '../../static/img/BarGroup.svg';
import LinePreview from '../../static/img/Line.svg';
import ScatterPreview from '../../static/img/Scatter.svg';
import DistrictsPreview from '../../static/img/Districts.svg';
import RegionsPreview from '../../static/img/Regions.svg';
import DonutPreview from '../../static/img/Donut.svg';

const GroupByLabels = ({ groupby, mark, renderValue }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translate(100%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                gap: 2,
                maxWidth: 100,
            }}
        >
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: 12, height: 12, borderRadius: 1, bgcolor: '#122945', flex: 'none' }} />
                <Typography variant="caption">{`${renderValue(groupby)} 1`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: 12, height: 12, borderRadius: 1, bgcolor: '#f75151', flex: 'none' }} />
                <Typography variant="caption">{`${renderValue(groupby)} 2`}</Typography>
            </Box>
        </Box>
    );
};

const DateRangeFilter = ({ start, end }) => (
    <Typography
        variant="caption"
        color="primary"
        sx={{
            position: 'absolute',
            width: '100%',
            bottom: -60,
            textAlign: 'center',
        }}
    >
        {`${start ? start.toLocaleString(DateTime.DATE_SHORT) : 'Earliest'} - ${
            end ? end.toLocaleString(DateTime.DATE_SHORT) : 'Today'
        }`}
    </Typography>
);

export default function QueryPreview({ visualization, watch, getValues, renderValue, activeStep }) {
    const selectedOperation = watch('operation');
    const selectedMark = watch('mark');
    const selectedX = watch('x');
    const selectedY = watch('y');
    const selectedGroupby = watch('groupby');
    const selectedStart = watch('filters.start');
    const selectedEnd = watch('filters.end');

    const vizPreviewSrc = useMemo(() => {
        if (visualization === 'MAP') {
            return selectedX === 'REGION' ? RegionsPreview : DistrictsPreview;
        } else {
            switch (selectedMark) {
                case 'AreaStack':
                    return AreaStackPreview;
                case 'Bar':
                    return BarPreview;
                case 'BarStack':
                    return BarStackPreview;
                case 'BarGroup':
                    return BarGroupPreview;
                case 'Line':
                    return LinePreview;
                case 'Scatter':
                    return ScatterPreview;
                case 'Donut':
                    return DonutPreview;
                default:
                    return BarPreview;
            }
        }
    }, [visualization, selectedX, selectedMark]);

    const title = useMemo(() => {
        const excludeOps = selectedY.endsWith('_RATE') || selectedOperation === 'COUNT';
        const operation = excludeOps ? '' : selectedOperation + ' ';

        return `${operation}${renderValue(selectedY)} per ${renderValue(selectedX)} ${
            selectedGroupby ? 'by ' + renderValue(selectedGroupby) : ''
        }`.replace('_', ' ');
    }, [selectedX, selectedY, selectedOperation, selectedGroupby, renderValue]);

    return (
        <Box
            sx={{
                width: 420,
                pl: 4,
                mb: '36.5px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: 2,
            }}
        >
            <Typography variant="overline" color="primary" sx={{ width: '100%', textAlign: 'center' }}>
                {title}
            </Typography>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 260,
                        width: 260,
                        borderLeft: visualization === 'CHART' && selectedMark !== 'Donut' ? 'solid' : 'none',
                        borderLeftWidth: 1,
                        borderLeftColor: '#ddd',
                        borderBottom: visualization === 'CHART' && selectedMark !== 'Donut' ? 'solid' : 'none',
                        borderBottomWidth: 1,
                        borderBottomColor: '#ddd',
                    }}
                >
                    {(activeStep > 2 || visualization === 'MAP') && (
                        <img src={vizPreviewSrc} width={235} height={235} alt="Map image" />
                    )}
                    {visualization === 'CHART' && (
                        <>
                            <Typography
                                variant="caption"
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    bottom: 0,
                                    left: 0,
                                    textAlign: 'center',
                                    transform: 'translateY(100%) translateY(8px)',
                                }}
                            >
                                {renderValue(selectedX)}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    left: 0,
                                    bottom: 0,
                                    transform: 'rotate(-90deg) translateY(-8px)',
                                    transformOrigin: '0 100%',
                                    textAnchor: 'middle',
                                    textAlign: 'center',
                                }}
                            >
                                {renderValue(selectedY)}
                            </Typography>
                        </>
                    )}
                    {activeStep === 3 && getValues('groupby') && (
                        <GroupByLabels
                            renderValue={renderValue}
                            groupby={getValues('groupby')}
                            mark={getValues('mark')}
                        />
                    )}
                    {/* <DateRangeFilter start={selectedStart} end={selectedEnd} /> */}
                </Box>
            </Box>
        </Box>
    );
}
