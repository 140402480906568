import React, { forwardRef, Ref, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IconButton, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoneIcon from '@mui/icons-material/Done';
import cn from 'classnames';
import { YesNoDialog, YesNoDialogProps } from '../YesNoDialog';
import { openSnackBar } from '../Snackbar';
import { EditableLabel } from '../EditableLabel';
import { GET_FILTERS, GET_FILTER } from '../../api/apollo/query';
import { DELETE_FILTER_CONFIG, UPDATE_FILTER_CONFIG } from '../../api/apollo/mutation';
import { CustomDialog } from '../CustomDialog';
import { AdvancedFilterContext } from '../../routes/SummaryTables/components/AdvancedFilter/AdvancedFilterContext';
import styles from './AdvancedFiltersManager.module.css';

interface ManagerRef {
    close?: () => void;
    chooseFilterConfig?: (filterConfig: any) => void;
}

export interface RawAdvancedFilter {
    id: string;
    title: string;
    config?: string | null;
}

export interface AdvancedFiltersRef {
    open: () => Promise<RawAdvancedFilter | void>;
    addFilterConfig: (id: string, title: string) => void;
}

interface AdvancedFiltersProps {
    onFilters: (filters: RawAdvancedFilter[]) => void;
}

export const AdvancedFiltersManager = forwardRef(
    ({ onFilters }: AdvancedFiltersProps, ref: Ref<AdvancedFiltersRef | undefined>) => {
        const abortRef = useRef(new AbortController());
        const [dialogOpen, setDialogOpen] = useState<boolean>(false);
        const [loading, setLoading] = useState<boolean>(false);
        const [confirmDialog, setConfirmDialog] = useState<YesNoDialogProps | undefined>();
        const { currentFilter } = useContext(AdvancedFilterContext);

        const fetchOptions = {
            fetchOptions: {
                signal: abortRef.current.signal,
            },
        };
        const onError = () => {
            openSnackBar({
                message: 'Connection error. Please check your internet connection and try again.',
                type: 'error',
            });
            setLoading(false);
        };

        const [updateFilter] = useMutation(UPDATE_FILTER_CONFIG, {
            context: {
                ...fetchOptions,
            },
            onError,
        });
        const [removeFilter] = useMutation(DELETE_FILTER_CONFIG, {
            context: {
                ...fetchOptions,
            },
            onError,
        });
        const [fetchFilters, { data: filters, loading: filtersLoading, called }] = useLazyQuery(GET_FILTERS, {
            context: {
                ...fetchOptions,
            },
            fetchPolicy: 'network-only',
            onError,
        });
        const [fetchFilter] = useLazyQuery(GET_FILTER, {
            context: {
                ...fetchOptions,
            },
            fetchPolicy: 'network-only',
            onError,
        });
        const [currentFilters, setCurrentFilters] = useState<RawAdvancedFilter[]>([]);
        const managerRef = useRef<ManagerRef>({});

        useEffect(() => {
            onFilters(currentFilters);
        }, [currentFilters]);

        useEffect(() => {
            if (!filtersLoading && called) {
                setCurrentFilters(filters.user.advancedCourtCaseFilterConfigs as RawAdvancedFilter[]);
            }
        }, [filters, called]);

        useImperativeHandle(
            ref,
            () => {
                return {
                    open: () => {
                        setDialogOpen(true);
                        return new Promise<RawAdvancedFilter | void>(async (resolve) => {
                            setLoading(true);
                            await fetchFilters();

                            managerRef.current = {
                                close: () => {
                                    setDialogOpen(false);
                                    resolve();
                                },
                                chooseFilterConfig: (filterConfig: RawAdvancedFilter) => {
                                    setDialogOpen(true);
                                    resolve(filterConfig);
                                },
                            };
                            setLoading(false);
                        });
                    },
                    addFilterConfig: (id: string, title: string) => {
                        setCurrentFilters((prev) => {
                            return [{ id, title }].concat(prev);
                        });
                    },
                };
            },
            [setCurrentFilters]
        );

        const handleClose = () => {
            if (managerRef.current?.close) {
                managerRef.current.close();
            }
            abortRef.current.abort();
            abortRef.current = new AbortController();
        };

        const removeFilterConfig = (configId: string) => {
            return async () => {
                setConfirmDialog({
                    open: true,
                    onClose: () => {
                        setConfirmDialog((prev) => (prev ? { ...prev, open: false } : prev));
                    },
                    onConfirm: async () => {
                        setConfirmDialog((prev) => (prev ? { ...prev, open: false } : prev));

                        setLoading(true);
                        await removeFilter({
                            variables: {
                                filterConfig: { id: configId },
                            },
                        });
                        setCurrentFilters((prev) => {
                            const cloned = prev.slice();
                            const index = cloned.findIndex((item) => item.id === configId);

                            if (index !== -1) {
                                cloned.splice(index, 1);
                            }

                            return cloned;
                        });
                        setLoading(false);
                    },
                    title: 'Remove Filter',
                    message: 'Do you want to remove this filter?',
                });
            };
        };

        const renameFilterConfig = (configId: string) => {
            return async (value: string) => {
                setLoading(true);

                try {
                    const {
                        data: { advancedCourtCaseFilterConfig },
                    } = await fetchFilter({ variables: { id: configId } });
                    await updateFilter({
                        variables: {
                            filterConfig: {
                                id: configId,
                                title: value,
                                config: advancedCourtCaseFilterConfig.config,
                            },
                        },
                    });
                    setCurrentFilters((prev) =>
                        prev.map((item) => {
                            if (item.id === configId) {
                                item.title = value;
                            }
                            return item;
                        })
                    );
                } catch {
                    return;
                }

                setLoading(false);
            };
        };

        const nameValidator = (value: string): { valid: boolean; message?: string } => {
            if (!value || value.length === 0) {
                return { valid: false, message: 'The "name" field cannot be empty.' };
            }
            if (currentFilters.find((item) => item.title === value)) {
                return { valid: false, message: 'A filter with the current name is already defined.' };
            }
            return { valid: true };
        };

        const selectFilter = (filter: RawAdvancedFilter) => {
            return () => {
                setConfirmDialog({
                    open: true,
                    onClose: () => {
                        setConfirmDialog((prev) => (prev ? { ...prev, open: false } : prev));
                    },
                    onConfirm: async () => {
                        setConfirmDialog((prev) => (prev ? { ...prev, open: false } : prev));

                        setLoading(true);
                        if (managerRef.current.chooseFilterConfig) {
                            const {
                                data: { advancedCourtCaseFilterConfig },
                            } = await fetchFilter({ variables: { id: filter.id } });

                            managerRef.current.chooseFilterConfig({
                                ...filter,
                                config: advancedCourtCaseFilterConfig.config,
                            });
                        }

                        setDialogOpen(false);
                        setLoading(false);
                    },
                    title: 'Filter',
                    message: 'Dou you want to use this filter?',
                });
            };
        };

        return (
            <>
                {confirmDialog !== undefined && <YesNoDialog {...confirmDialog} />}
                <CustomDialog open={dialogOpen} loading={loading} onClose={handleClose} title="Advanced Filters">
                    <div>
                        {currentFilters.length === 0 && <Typography>No filters found.</Typography>}
                        {currentFilters.map((filter) => (
                            <div key={filter.id} className={styles.filterBlock}>
                                {currentFilter?.id === filter.id && (
                                    <div className={styles.currentFilter}>
                                        <Typography className={styles.currentFilterText}>
                                            Filter already in use.
                                        </Typography>
                                    </div>
                                )}
                                <div
                                    className={cn(
                                        styles.filterInnerBlock,
                                        currentFilter?.id === filter.id && styles.filterInnerBlockDisabled
                                    )}
                                >
                                    <div className={styles.filterLabelBlock}>
                                        <IconButton onClick={selectFilter(filter)}>
                                            <DoneIcon />
                                        </IconButton>
                                        <EditableLabel
                                            label={filter.title}
                                            onChange={renameFilterConfig(filter.id)}
                                            validator={nameValidator}
                                        />
                                    </div>
                                    <IconButton onClick={removeFilterConfig(filter.id)}>
                                        <DeleteOutlineOutlinedIcon color="primary" />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>
                </CustomDialog>
            </>
        );
    }
);
