import { gql } from '@apollo/client';

const DELETE_FILTER_CONFIG = gql`
    mutation deleteFilterConfig($filterConfig: FilterConfigInput!) {
        deleteFilterConfig(filterConfig: $filterConfig) {
            ok
        }
    }
`;

export default DELETE_FILTER_CONFIG;
