import { ReactNode } from 'react';
import { capitalize, startCase } from 'lodash';

import { AutocompleteInput } from '../components/FilterInputs/AutocompleteInput';
import { FilterComponentGeneralInterface } from '../components/FilterComponent';
import { FreeTextArrayInput } from '../components/FilterInputs/FreeTextArrayInput';
import { BooleanInput } from '../components/FilterInputs/BooleanInput';
import { ComparisonFilterProps, ComparisonInput } from '../components/FilterInputs/ComparisonInput';

import { Region } from '../../../../../types/Region';
import { Court } from '../../../../../types/Court';
import { Commodity, Species } from '../../../../../types';

import { FilterFunction } from '../index';
import { addComponentToField } from './courtCaseSetupUtils';
import { AdvancedFilterContextInterface } from '../AdvancedFilterContext';
import { DateComparisonFilterProps, DateInput } from '../components/FilterInputs/DateInput';

export interface SetupInterface {
    operator: string;
    groupName: string;
    label: string;
    getComponent?: (
        context: AdvancedFilterContextInterface,
        onFilterFunction: (prev: string, current: string, func: FilterFunction) => void
    ) => ReactNode;
}

export const COURT_CASES_GROUP = 'Court Cases';
export const PROPOSED_SENTENCES_GROUP = 'Proposed Sentences';
export const SENTENCES_GROUP = 'Sentences';
export const SEIZED_COMMODITIES_GROUP = 'Seized Commodities';
export const PARTICIPANTS_GROUP = 'Participants';

// Record<string -> keyof CourtCaseFilter,
export const CourtCasesSetup: Record<string, SetupInterface> = {
    docket: {
        operator: 'in',
        groupName: COURT_CASES_GROUP,
        label: 'Docket',
    },
    defendants: {
        operator: 'in',
        groupName: COURT_CASES_GROUP,
        label: 'Defendants',
    },
    magistrates: {
        operator: 'in',
        groupName: COURT_CASES_GROUP,
        label: 'Magistrates',
    },
    prosecutors: {
        operator: 'in',
        groupName: COURT_CASES_GROUP,
        label: 'Prosecutors',
    },
    defenseAttorneys: {
        operator: 'in',
        groupName: COURT_CASES_GROUP,
        label: 'Defense Attorneys',
    },

    courts: {
        operator: 'autocomplete-in',
        groupName: COURT_CASES_GROUP,
        label: 'Court',
        getComponent: ({ user }, onFilterFunction) => {
            if (!user) {
                return;
            }

            return (
                <AutocompleteInput<Court, Partial<Court>>
                    options={user.organization.country.courts}
                    getLabel={(option) => option.name}
                    getValue={(option) => ({ id: option.id, name: option.name })}
                    field="courts"
                    onFilterFunction={onFilterFunction}
                    multiple={true}
                />
            );
        },
    },
    administrativeLevel1: {
        operator: 'autocomplete-in',
        groupName: COURT_CASES_GROUP,
        label: 'District',
        getComponent: ({ user }, onFilterFunction) => {
            if (!user) {
                return;
            }

            return (
                <AutocompleteInput<Region, Partial<Region>>
                    options={user.organization.country.administrativeLevel1Regions}
                    getLabel={(option) => option.name}
                    getValue={(option) => ({ id: option.id, name: option.name })}
                    field="administrativeLevel1"
                    onFilterFunction={onFilterFunction}
                    multiple={true}
                />
            );
        },
    },
    regions: {
        operator: 'autocomplete-in',
        groupName: COURT_CASES_GROUP,
        label: 'Region',
        getComponent: ({ user }, onFilterFunction) => {
            if (!user) {
                return;
            }

            return (
                <AutocompleteInput<Region, Region>
                    options={user.organization.country.regions}
                    getLabel={(option) => option.name}
                    getValue={(option) => option}
                    field="regions"
                    onFilterFunction={onFilterFunction}
                    multiple={true}
                />
            );
        },
    },
    legislation: {
        operator: 'autocomplete-in',
        groupName: COURT_CASES_GROUP,
        label: 'Legislation',
        getComponent: ({ legislation }, onFilterFunction) => {
            return (
                <AutocompleteInput<string, string>
                    options={legislation}
                    getLabel={(option) => option}
                    getValue={(option) => option}
                    field="legislation"
                    onFilterFunction={onFilterFunction}
                    multiple={true}
                />
            );
        },
    },
    legislationPart: {
        operator: 'autocomplete-in',
        groupName: COURT_CASES_GROUP,
        label: 'Legislation Parts',
        getComponent: ({ legislationParts }, onFilterFunction) => {
            return (
                <AutocompleteInput<string, string>
                    options={legislationParts}
                    getLabel={(option) => option}
                    getValue={(option) => option}
                    field="legislationPart"
                    onFilterFunction={onFilterFunction}
                    multiple={true}
                />
            );
        },
    },
    protectionLevel: {
        operator: 'autocomplete-in',
        groupName: SEIZED_COMMODITIES_GROUP,
        label: 'Protection Level',
        getComponent: ({ protectionLevelOptions }, onFilterFunction) => {
            return (
                <AutocompleteInput<string, string>
                    options={protectionLevelOptions}
                    getLabel={(option) => startCase(option.replace('_', ' ').toLowerCase())}
                    getValue={(option) => option}
                    onFilterFunction={onFilterFunction}
                    field="protectionLevel"
                    multiple={false}
                />
            );
        },
    },
    species: {
        operator: 'autocomplete-in',
        groupName: SEIZED_COMMODITIES_GROUP,
        label: 'Species',
        getComponent: ({ trackedSpecies }, onFilterFunction) => {
            return (
                <AutocompleteInput<Species, Species>
                    options={trackedSpecies}
                    getLabel={(option) => option.commonName}
                    getValue={(option) => option}
                    onFilterFunction={onFilterFunction}
                    field="species"
                    multiple={true}
                />
            );
        },
    },
    commodity: {
        operator: 'autocomplete-in',
        groupName: SEIZED_COMMODITIES_GROUP,
        label: 'Commodity Type',
        getComponent: ({ commodityOptions }, onFilterFunction) => {
            return (
                <AutocompleteInput<Commodity, Commodity>
                    options={commodityOptions}
                    getLabel={(option) =>
                        `${option.species?.commonName} ${option.detail?.detail} ${
                            option.state?.state ? '(' + capitalize(option.state.state) + ')' : ''
                        }` || ''
                    }
                    getValue={(option) => option}
                    onFilterFunction={onFilterFunction}
                    field="commodity"
                    multiple={true}
                />
            );
        },
    },
    latestHearingStage: {
        operator: 'is',
        groupName: COURT_CASES_GROUP,
        label: 'Latest Hearing Stage',
        getComponent: ({ hearingStageOptions }, onFilterFunction) => {
            return (
                <AutocompleteInput<string, string>
                    options={hearingStageOptions}
                    getValue={(option) => option}
                    getLabel={(option) => option}
                    field="latestHearingStage"
                    onFilterFunction={onFilterFunction}
                    multiple={false}
                />
            );
        },
    },
    isConcluded: {
        operator: 'is',
        groupName: COURT_CASES_GROUP,
        label: 'Case Closed',
    },
    pretrial: {
        operator: 'is',
        groupName: COURT_CASES_GROUP,
        label: 'Pretrial',
    },
    bailMade: {
        operator: 'is',
        groupName: COURT_CASES_GROUP,
        label: 'Bail',
    },
    isConviction: {
        operator: 'is',
        groupName: COURT_CASES_GROUP,
        label: 'Conviction',
    },
    absconded: {
        operator: 'is',
        groupName: COURT_CASES_GROUP,
        label: 'Absconded',
    },
    guiltyPlea: {
        operator: 'is',
        groupName: COURT_CASES_GROUP,
        label: 'Guilty Plea',
    },

    earliestHearingDate: {
        operator: 'dateComparison',
        groupName: COURT_CASES_GROUP,
        label: 'Earliest Hearing Date',
    },
    latestHearingDate: {
        operator: 'dateComparison',
        groupName: COURT_CASES_GROUP,
        label: 'Latest Hearing Date',
    },
    caseDuration: {
        operator: 'comparison',
        groupName: COURT_CASES_GROUP,
        label: 'Case Duration',
    },
    defendantCount: {
        operator: 'comparison',
        groupName: COURT_CASES_GROUP,
        label: 'Defendant Count',
    },
    defenseAttorneyCount: {
        operator: 'comparison',
        groupName: COURT_CASES_GROUP,
        label: 'Defense Attorney Count',
    },
    magistrateCount: {
        operator: 'comparison',
        groupName: COURT_CASES_GROUP,
        label: 'Magistrate Count',
    },
    proposedCustodialMonths: {
        operator: 'comparison',
        groupName: PROPOSED_SENTENCES_GROUP,
        label: 'Proposed Custodial Months',
    },
    proposedFine: {
        operator: 'comparison',
        groupName: PROPOSED_SENTENCES_GROUP,
        label: 'Proposed Fine',
    },
    proposedCommunityServiceHours: {
        operator: 'comparison',
        groupName: PROPOSED_SENTENCES_GROUP,
        label: 'Proposed Community Service Hours',
    },
    proposedHardLaborMonths: {
        operator: 'comparison',
        groupName: PROPOSED_SENTENCES_GROUP,
        label: 'Proposed Hard Labor Months',
    },
    custodialMonths: {
        operator: 'comparison',
        groupName: SENTENCES_GROUP,
        label: 'Custodial Sentence (Months)',
    },
    suspendedMonths: {
        operator: 'comparison',
        groupName: SENTENCES_GROUP,
        label: 'Suspended Sentence (Months)',
    },
    fine: {
        operator: 'comparison',
        groupName: SENTENCES_GROUP,
        label: 'Fine',
    },
    suspendedFine: {
        operator: 'comparison',
        groupName: SENTENCES_GROUP,
        label: 'Suspended Fine',
    },
    communityServiceHours: {
        operator: 'comparison',
        groupName: SENTENCES_GROUP,
        label: 'Community Service (Hours)',
    },
    hardLaborMonths: {
        operator: 'comparison',
        groupName: SENTENCES_GROUP,
        label: 'Hard Labor (Months)',
    },
    seizedCommoditiesWeightKg: {
        operator: 'comparison',
        groupName: SEIZED_COMMODITIES_GROUP,
        label: 'Weight (kg)',
    },
    seizedCommoditiesCount: {
        operator: 'comparison',
        groupName: SEIZED_COMMODITIES_GROUP,
        label: 'Count',
    },
    seizedCommoditiesValue: {
        operator: 'comparison',
        groupName: SEIZED_COMMODITIES_GROUP,
        label: 'Value (MVK)',
    },
};

addComponentToField<FilterComponentGeneralInterface>(CourtCasesSetup, 'in', FreeTextArrayInput);
addComponentToField<FilterComponentGeneralInterface>(CourtCasesSetup, 'is', BooleanInput);
addComponentToField<Pick<ComparisonFilterProps<number>, 'parseValue' | 'inputType'>>(
    CourtCasesSetup,
    'comparison',
    ComparisonInput,
    {
        parseValue: (value) => {
            const num = Number(value);
            return isNaN(num) ? 0 : num;
        },
        inputType: 'number',
    }
);
addComponentToField<DateComparisonFilterProps>(CourtCasesSetup, 'dateComparison', DateInput);
