import { Context, useContext, useEffect, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Button, Switch } from '@mui/material';
import styles from '../../Account.module.css';

export type ChangePermissionsSettingsFunc = (status: boolean, codeName: string, userId: string) => void;

interface PermissionToggleCellProps {
    params: GridRenderCellParams;
    codename: string;
    changePermissionsSettings: ChangePermissionsSettingsFunc;
    checked?: boolean;
}

export type PermissionSettings = Record<string, Record<string, boolean>>;

export const PermissionToggleCell = ({
    params,
    codename,
    changePermissionsSettings,
    checked: initialValue,
}: PermissionToggleCellProps) => {
    const [checked, setChecked] = useState<boolean>(initialValue !== undefined ? initialValue : false);

    const changePermission = (permission: boolean) => {
        setChecked(permission);
        changePermissionsSettings(permission, codename, params.row.id);
    };

    return <Switch color="secondary" checked={checked} onChange={(e) => changePermission(e.target.checked)} />;
};

export const makeEditPermissionsButton = (
    context: Context<PermissionSettings>,
    editPermissions: (id: string) => void
) => {
    return ({ id }: { id: string }) => {
        const ctx = useContext<PermissionSettings>(context);
        const [edited, setEdited] = useState<boolean>(!!ctx[id]);

        useEffect(() => {
            !ctx[id] ? setEdited(false) : setEdited(true);
        }, [ctx]);

        return (
            <Button
                className={edited ? styles.editPermissions : styles.disabledEditPermissions}
                onClick={() => {
                    if (edited) {
                        editPermissions(id);
                    }
                }}
                disabled={!edited}
            >
                {!edited ? 'Edit Permissions' : 'Save Permissions'}
            </Button>
        );
    };
};
