import { gql } from "@apollo/client";

const GET_USERS = gql`
    query users {
        users {
            id
            email
            name
            firstName
            lastName
            isAdmin
            isSuperuser
            organization {
                id
                name
                country {
                    id
                    name
                }
            }
            permissions {
                id
                name
                codename
            }
        }
    }
`;

export default GET_USERS;