import React, { useState } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { Button, Switch } from '@c4ads/c4blocks';
import {
    styled,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    FormLabel,
    FormGroup,
    FormControlLabel,
    IconButton,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { FullScreenLoader } from '../../../../../components/Loader/FullScreenLoader';
import { useAuth } from '../../../../../api/auth';
import { EditableField } from '../../FieldItem';
import styles from '../SuperUser.module.css';

interface AddUserDialogProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    onUpdateList: () => void;
}

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 360,
    gap: theme.spacing(2),
    margin: '0 auto',
}));

export const AddUserDialog = ({ open, setOpen, onUpdateList }: AddUserDialogProps) => {
    const { user, signup } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const {
        control,
        formState: { errors },
        setError,
        reset,
        handleSubmit,
    } = useForm({
        defaultValues: {
            email: undefined,
            view_courtcase: true,
            view_details: true,
            view_defendant: true,
            view_seizure: true,
            view_seizedcommodity: true,
            view_hearing: true,
            view_indictmentcharge: true,
            can_export_tables: true,
            can_export_charts: true,
            first_name: undefined,
            last_name: undefined,
        },
    });

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit: SubmitHandler<any> = async ({ passwordConfirmation, ...userData }) => {
        if (user?.isAdmin || user?.isSuperuser) {
            setLoading(true);
            const { email, first_name, last_name, ...permissions } = userData;
            const organization_id = user?.organization.id;
            const permission_codenames: string[] = [];

            Object.entries(permissions).forEach(([k, v]) => {
                v && permission_codenames.push(k);
            });

            const data = { email, first_name, last_name, organization_id, permission_codenames };

            const result = await signup(data);
            if (!result) {
                setError('email', { type: 'required', message: 'User with current email already registered.' });
                setLoading(false);
                return;
            }
            onUpdateList();
            setLoading(false);
        }
        reset();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            {loading && (
                <div className={styles.loaderWrapper}>
                    <FullScreenLoader />
                </div>
            )}
            <DialogTitle>Invite User</DialogTitle>
            <IconButton className={styles.closeButton} onClick={handleClose}>
                <HighlightOffIcon className={styles.closeIcon} />
            </IconButton>
            <DialogContent>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <EditableField
                        name="email"
                        control={control}
                        formProps={{
                            rules: {
                                required: 'User email is required.',
                            },
                        }}
                        label="Email"
                        placeholder="Enter email"
                    />
                    <div className={styles.nameContainer}>
                        <EditableField
                            name="first_name"
                            control={control}
                            formProps={{
                                rules: {
                                    required: 'First name is required.',
                                },
                            }}
                            label="Name"
                            placeholder="First name"
                        />
                        <EditableField
                            name="last_name"
                            control={control}
                            formProps={{
                                rules: {
                                    required: 'Last name is required.',
                                },
                            }}
                            placeholder="Last name"
                        />
                    </div>

                    <FormGroup>
                        <div className={styles.dividerBlock}>
                            <FormLabel className={styles.dividerBlockLabel}>Permissions</FormLabel>
                            <div className={styles.dividerLine} />
                        </div>

                        <Controller
                            name="view_courtcase"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            View Court Cases
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="view_details"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            View Court Case Details
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="view_defendant"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            View Defendants
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="view_seizure"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            View Seizures
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="view_seizedcommodity"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            View Seized Commodities
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="view_indictmentcharge"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            View Charges
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="view_hearing"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            View Hearings
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="can_export_charts"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            Can Export Charts
                                        </Typography>
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="can_export_tables"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} defaultChecked />}
                                    label={
                                        <Typography variant="overline" color="primary">
                                            Can Export Tables
                                        </Typography>
                                    }
                                />
                            )}
                        />
                    </FormGroup>
                    <Button type="submit" variant="contained">
                        Send Invite
                    </Button>
                </StyledForm>
            </DialogContent>
        </Dialog>
    );
};
