import Placeholder from '../../components/PlaceHolder';

const RegionalTrends = () => {
    return <Placeholder title="Under Construction" icon="engineering" />;
};

const RegionalTrendsRoute = {
    routeProps: {
        path: '/regional-trends',
        element: <RegionalTrends />,
    },
    name: 'Regional Trends',
};

export default RegionalTrendsRoute;
