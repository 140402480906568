import React, { forwardRef, Ref, useContext, useImperativeHandle, useRef, useState } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    TextField,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@c4ads/c4blocks';
import CloseIcon from '@mui/icons-material/Close';
import { StyledForm } from '../StyledForm';
import { DashboardsContext } from '../../routes/Dashboards/DashboardsContext';
import { emptyDashboard } from '../../routes/Dashboards/Dashboard/useDashboard';
import { CREATE_DASHBOARD_CONFIG } from '../../api/apollo/mutation';
import styles from './DashboardCreateForm.module.css';

interface FormData {
    title: string;
    id: string;
}

export interface DashboardCreateFormRef {
    open: () => Promise<FormData | undefined>;
}

export const DashboardCreateForm = forwardRef((props, ref: Ref<DashboardCreateFormRef | undefined>) => {
    const [createDashboard] = useMutation(CREATE_DASHBOARD_CONFIG);
    const { getDashboards } = useContext(DashboardsContext);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const formRef = useRef<{ onSubmit?: (data?: FormData) => void }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const {
        control,
        formState: { errors },
        setError,
        setValue,
        handleSubmit,
    } = useForm({
        defaultValues: {
            title: '',
        },
    });

    useImperativeHandle(
        ref,
        () => {
            return {
                open: async () => {
                    setDialogOpen(true);
                    return new Promise((resolve) => {
                        formRef.current.onSubmit = (data) => {
                            resolve(data);
                        };
                    });
                },
            };
        },
        []
    );

    const onSubmit: SubmitHandler<any> = async ({ ...dashboardData }) => {
        setError('title', { type: 'connection', message: undefined });
        if (!checkAvailability(dashboardData.title)) {
            return;
        }
        setLoading(true);
        const { data, errors } = await createDashboard({
            variables: {
                dashboardConfig: {
                    title: dashboardData.title,
                    config: JSON.stringify(emptyDashboard),
                },
            },
        });

        if (errors) {
            setError('title', { type: 'connection', message: 'Something went wrong try again' });
            return;
        }
        setLoading(false);

        setValue('title', '');
        setDialogOpen(false);

        const dashboardConfig = data.createDashboardConfig.dashboardConfig;
        formRef.current.onSubmit &&
            formRef.current.onSubmit({
                id: dashboardConfig.id,
                title: dashboardConfig.title,
            });
    };

    const handleClose = () => {
        if (loading) {
            return;
        }
        setDialogOpen(false);
        formRef.current.onSubmit && formRef.current.onSubmit();
    };

    const checkAvailability = (title: string) => {
        const dashboardTitles = getDashboards(true).map((item) => item.title);

        if (dashboardTitles.includes(title)) {
            setError('title', { type: 'duplicate', message: 'Dashboard with the current name already exists' });
        } else {
            setError('title', {});
        }

        return !dashboardTitles.includes(title);
    };

    return (
        <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="xs">
            {loading && (
                <div className={styles.loaderMask}>
                    <CircularProgress />
                </div>
            )}
            <IconButton className={styles.closeIcon} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogTitle>Create Dashboard</DialogTitle>
            <DialogContent>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="title"
                        control={control}
                        rules={{
                            required: 'Title is required.',
                        }}
                        render={({ field }) => <TextField placeholder="Enter dashboard name" {...field} />}
                    />
                    {Object.keys(errors).filter((key) => !!errors[key].message).length !== 0 &&
                        Object.keys(errors).map((key) => (
                            <FormHelperText key={key} color="error">
                                {errors[key]?.message as string}
                            </FormHelperText>
                        ))}
                    <Button type="submit" color="secondary" variant="contained">
                        Create
                    </Button>
                </StyledForm>

            </DialogContent>
        </Dialog>
    );
});
