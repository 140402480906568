import { gql } from '@apollo/client';

const GET_SPECIES =  gql`
    query species {
        species {
            id
            commonName
            kingdom
            scientificName
        }
    }
`;

export default GET_SPECIES;