import {
    GridColumnMenuContainer,
    GridColumnMenuProps,
    SortGridMenuItems,
    GridFilterMenuItem,
    HideGridColMenuItem,
    GridColumnsMenuItem,
} from '@mui/x-data-grid';

export default function CommoditiesTableColumnMenu(props: GridColumnMenuProps) {
    const { hideMenu, currentColumn, ...other } = props;

    return (
        <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} {...other}>
            <SortGridMenuItems onClick={hideMenu} column={currentColumn!} />
            <GridFilterMenuItem onClick={hideMenu} column={currentColumn!} />
            <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />
            <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
        </GridColumnMenuContainer>
    );
}
