import { useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, Icon, IconButton } from '@mui/material';
import QueryForm from './QueryForm';

export default function QueryBuilderDialog({ editConfig, setEditConfig, handleCreateChart, handleUpdateChartConfig }) {
    const handleClose = useCallback(() => {
        setEditConfig(null);
    }, [setEditConfig]);

    const handleSubmitChartConfig = useCallback(
        (newConfig: any) => {
            if (editConfig?.id) {
                handleUpdateChartConfig(editConfig.id, newConfig);
            } else {
                handleCreateChart(newConfig);
            }
            handleClose();
        },
        [editConfig, handleCreateChart, handleUpdateChartConfig, handleClose]
    );

    return (
        <Dialog
            open={Boolean(editConfig)}
            fullWidth
            maxWidth='md'
            sx={{
                '& .MuiPaper-root': {
                    minHeight: 440,
                    px: 2,
                    py: 1,
                },
                '& .MuiDialogActions-root': {
                    p: 2,
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {editConfig?.id ? 'Edit Chart' : 'Create Chart'}
                <IconButton onClick={handleClose}>
                    <Icon>close</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {editConfig && (
                    <QueryForm defaultValues={editConfig} handleSubmitChartConfig={handleSubmitChartConfig} />
                )}
            </DialogContent>
        </Dialog>
    );
}
