const fixSvgFonts = (el: SVGElement, selectors: string[]) => {
    const style = document.createElement('style');
    /* Century Gothic */
    style.innerHTML = "@import url('https://fonts.cdnfonts.com/css/century-gothic');";

    const textElements = el.querySelectorAll(selectors.join(','));
    textElements.forEach((svgText) => {
        svgText.setAttribute('font-family', 'Century Gothic, sans-serif');
        svgText.setAttribute('font-weight', 'normal');
    });

    el.prepend(style);
};

const makeImageFromSvg = async (el: SVGSVGElement) => {
    const blob = new Blob([el.outerHTML], { type: 'image/svg+xml;charset=utf-8' });

    let image = new Image();
    image.setAttribute('crossorigin', 'anonymous');

    const fileReader = new FileReader();
    return new Promise<HTMLImageElement | null>((resolve) => {
        fileReader.onload = function (event) {
            const base64String = event?.target?.result;
            image.onload = () => {
                resolve(image);
            };
            if (base64String) {
                // @ts-ignore
                image.src = base64String;
            } else {
                resolve(null);
            }
        };
        fileReader.readAsDataURL(blob);
    });
};

const drawImageOnCanvas = (image: HTMLImageElement, width: number, height: number) => {
    let canvas = document.createElement('canvas');

    canvas.width = width * window.devicePixelRatio;
    canvas.height = height * window.devicePixelRatio;

    canvas.setAttribute('style', `width: ${width}px; height: ${height}px;`);

    let context = canvas.getContext('2d');
    if (context) {
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, width * window.devicePixelRatio, height * window.devicePixelRatio);
        context.drawImage(image, 0, 0, width * window.devicePixelRatio, height * window.devicePixelRatio);
    }

    return canvas;
};

export const downloadSvg = async (el: SVGSVGElement, name: string, format: 'png' | 'jpg' = 'png') => {
    const clonedEl = el.cloneNode() as SVGElement;
    const width = Number(el.getAttribute('width'));
    const height = Number(el.getAttribute('height'));

    clonedEl.innerHTML = el.innerHTML;
    clonedEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    fixSvgFonts(clonedEl, ['.visx-axis-label', 'text']);

    const image = await makeImageFromSvg(clonedEl as SVGSVGElement);
    if (!image) {
        return false;
    }

    const canvas = drawImageOnCanvas(image, width, height);

    const imageUrl = canvas.toDataURL(`image/${format}`, 1);
    const a = document.createElement('a');
    a.href = imageUrl;
    a.download = `${name}.${format}`;
    a.click();

    a.remove();
    clonedEl.remove();

    canvas.remove();
    image.remove();
    URL.revokeObjectURL(imageUrl);

    return true;
};

const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
};

export const svgToImageSrc = (
    el: SVGElement,
    quality: number = 5
): Promise<
    | {
          src: string;
          width: number;
          height: number;
      }
    | undefined
> => {
    const clonedEl = el.cloneNode() as SVGElement;
    clonedEl.innerHTML = el.innerHTML;
    clonedEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    fixSvgFonts(clonedEl, ['.visx-axis-label', 'text']);

    return new Promise<
        | {
              src: string;
              width: number;
              height: number;
          }
        | undefined
    >(async (resolve) => {
        const blob = new Blob([clonedEl.outerHTML], { type: 'image/svg+xml' });
        const base64 = await blobToBase64(blob);

        const img = new Image();
        img.src = base64;

        img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
                resolve(undefined);
                return;
            }

            canvas.width = img.width * quality;
            canvas.height = img.height * quality;

            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width * quality, img.height * quality);

            const pngDataUrl = canvas.toDataURL('image/png', 1);
            canvas.remove();
            img.remove();

            resolve({
                src: pngDataUrl,
                width: img.width,
                height: img.height,
            });
        };
    });
};
