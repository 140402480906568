import { gql } from '@apollo/client';

const UPDATE_FILTER_CONFIG = gql`
    mutation updateFilterConfig($filterConfig: FilterConfigInput!) {
        updateFilterConfig(filterConfig: $filterConfig) {
            filterConfig {
                id
                title
            }
        }
    }
`;

export default UPDATE_FILTER_CONFIG;
