import { useEffect, useMemo } from 'react';
import { styled, Box, FormControl, Icon, MenuItem, Stack, Typography } from '@mui/material';
import { Select } from '@c4ads/c4blocks';
import * as _ from 'lodash';
import Grid from '@mui/material/Unstable_Grid2';
import { Controller } from 'react-hook-form';
import { GroupBy } from '../../types/GroupBy';
import { DiscreteVariable } from '../../types/DiscreteVariable';

const StyledItem = styled(Box)(({ theme }) => ({
    height: 120,
    padding: theme.spacing(2),
    border: 'solid',
    borderRadius: theme.spacing(1),
    borderWidth: 2,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
}));

const X_OPTIONS: DiscreteVariable[] = [
    'MONTH',
    'QUARTER',
    'YEAR',
    'COURT',
    'ADMINISTRATIVE_LEVEL_1',
    'REGION',
    'COMMODITY',
    'SPECIES',
    'PROTECTION_LEVEL',
];

function isDisjointSet(x: DiscreteVariable, groupby: DiscreteVariable) {
    switch (x) {
        case 'MONTH':
            return ['QUARTER', 'YEAR'].includes(groupby);
        case 'QUARTER':
            return groupby === 'YEAR';
        case 'COURT':
            return ['ADMINISTRATIVE_LEVEL_1', 'REGION'].includes(groupby);
        case 'ADMINISTRATIVE_LEVEL_1':
            return groupby === 'REGION';
        case 'COMMODITY':
            return ['SPECIES', 'PROTECTION_LEVEL'].includes(groupby);
        case 'SPECIES':
            return groupby === 'PROTECTION_LEVEL';
        default:
            return false;
    }
}

export default function AppearanceFields({ visualization, control, setValue, renderValue, watch }) {
    const selectedX = watch('x');
    const selectedY = watch('y');
    const selectedOperation = watch('operation');
    const selectedGroupBy = watch('groupby');
    const selectedMark = watch('mark');

    const GROUPBY_OPTIONS: DiscreteVariable[] = useMemo(() => {
        return X_OPTIONS.filter((x) => x !== selectedX);
    }, [selectedX, setValue]);

    useEffect(() => {
        if (selectedGroupBy && !GROUPBY_OPTIONS.includes(selectedGroupBy)) {
            setValue('groupby', GROUPBY_OPTIONS[0]);
        }
    }, [selectedGroupBy, GROUPBY_OPTIONS, setValue]);

    const markIcon = {
        Bar: 'bar_chart',
        BarGroup: 'grouped_bar_chart',
        BarStack: 'stacked_bar_chart',
        MultiBar: 'grouped_bar_chart',
        Line: 'stacked_line_chart',
        Donut: 'donut_small',
        Scatter: 'scatter_plot',
        AreaStack: 'area_chart',
    };

    const MARK_OPTIONS = useMemo(() => {
        let allOptions: (string | null)[] = [];

        const isDate = (value: string) => ['MONTH', 'QUARTER', 'YEAR'].includes(value);

        if (selectedY.endsWith('_RATE')) {
            if (selectedGroupBy) {
                allOptions = isDisjointSet(selectedX, selectedGroupBy)
                    ? ['MultiBar', 'Scatter']
                    : ['BarGroup', 'Scatter'];
                isDate(selectedX) ? allOptions.push('Line') : allOptions.push('Donut');
            } else {
                allOptions = isDate(selectedX) ? ['Bar', 'Line', 'Scatter', 'Donut'] : ['Bar', 'Scatter', 'Donut'];
            }
        } else {
            if (selectedOperation.startsWith('CUMULATIVE_')) {
                if (selectedGroupBy) {
                    allOptions = ['AreaStack', 'BarStack', 'BarGroup', 'Line'];
                } else {
                    allOptions = ['Bar', 'AreaStack'];
                }
            } else {
                if (selectedGroupBy) {
                    allOptions = ['BarStack'];
                    isDisjointSet(selectedX, selectedGroupBy)
                        ? allOptions.push('MultiBar', 'Donut')
                        : allOptions.push('BarGroup', 'Donut');
                } else {
                    allOptions = ['Bar'];
                }
            }
        }

        if (allOptions.length < 4) {
            const emptyOptions = _.fill(new Array(4 - allOptions.length), null);
            allOptions = allOptions.concat(emptyOptions);
        }

        return allOptions;
    }, [selectedX, selectedY, selectedGroupBy, selectedOperation]);

    useEffect(() => {
        if (!MARK_OPTIONS.includes(selectedMark)) {
            setValue('mark', MARK_OPTIONS[0]);
        }
    }, [selectedMark, MARK_OPTIONS, setValue]);

    return (
        <>
            {visualization !== 'MAP' && (
                <FormControl>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="overline" color="#9e9e9e">
                            Segment By
                        </Typography>
                    </Stack>
                    {
                        <Controller
                            name="groupby"
                            control={control}
                            render={({ field }) => (
                                <FormControl>
                                    <Select
                                        size="small"
                                        {...field}
                                        displayEmpty
                                        variant="outlined"
                                        value={field.value}
                                        renderValue={(value) => <span>{renderValue(value as string) || 'None'}</span>}
                                    >
                                        <MenuItem value={undefined}>None</MenuItem>
                                        {GROUPBY_OPTIONS.map((option: GroupBy) => (
                                            <MenuItem key={option || 'none'} value={option}>
                                                {renderValue(option)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    }
                </FormControl>
            )}
            <FormControl>
                <Typography variant="overline" color="#9e9e9e">
                    Chart Type
                </Typography>
                <Grid container spacing={1}>
                    {MARK_OPTIONS.map((value: string | null, index: number) => (
                        <Grid xs={6} key={`mark-option-${index}`}>
                            <StyledItem
                                onClick={() => value && setValue('mark', value)}
                                sx={{
                                    color: value ? 'primary.dark' : 'action.disabled',
                                    borderColor: value && selectedMark === value ? 'primary' : 'white',
                                    cursor: value ? 'pointer' : 'default',
                                    borderWidth: 1,
                                }}
                            >
                                <Typography sx={{ flexGrow: 1 }} variant="body1">
                                    {value || `Chart ${index + 1}`}
                                </Typography>
                                <Box
                                    sx={{
                                        color: 'inherit',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Icon>{value ? markIcon[value] : 'lock'}</Icon>
                                </Box>
                            </StyledItem>
                        </Grid>
                    ))}
                </Grid>
            </FormControl>
        </>
    );
}
