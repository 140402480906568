import { Unstable_Grid2 as Grid } from '@mui/material';
import FieldItem from '../FieldItem';
import { FieldItemProps } from '../FieldItem';

export type FieldGridProps = {
    fields: FieldItemProps[];
};

export default function FieldGrid({ fields }: FieldGridProps) {
    return (
        <Grid container spacing={2}>
            {fields.map((fieldItemProps: FieldItemProps, i: number) => (
                <Grid key={i} xs={12} >
                    <FieldItem {...fieldItemProps} />
                </Grid>
            ))}
        </Grid>
    );
}
