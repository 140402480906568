// @ts-nocheck
import { Typography, Fade, Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
    GridExcelExportMenuItem,
    GridCsvExportMenuItem,
    GridPrintExportMenuItem,
    GridToolbarExportContainer,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import styles from './TableToolbar.module.css';

interface TableToolbarProps {
    title?: string;
    showTitle?: boolean;
    csvExportEnabled?: boolean;
    excelExportEnabled?: boolean;
    pdfExportEnabled?: boolean;
    onAdvancedFilterClick?: () => void;
    advancedFilterActive: boolean;
}

const TableToolbar = ({
    title,
    showTitle = false,
    csvExportEnabled = true,
    pdfExportEnabled = false,
    excelExportEnabled = true,
    onAdvancedFilterClick,
    advancedFilterActive,
}: TableToolbarProps) => {
    return (
        <GridToolbarContainer sx={{ border: 'none', justifyContent: 'space-between', px: 2 }}>
            <GridToolbarQuickFilter />
            <Fade in={showTitle} timeout={{ enter: 150, exit: 0 }}>
                <Typography variant="subtitle1" sx={{ color: 'var(--color-primary)' }}>
                    {title}
                </Typography>
            </Fade>
            <div className={styles.tooltipBlock}>
                {onAdvancedFilterClick && (
                    <Button variant="text" sx={{ color: 'var(--color-primary)' }} onClick={onAdvancedFilterClick}>
                        <FilterListIcon className={styles.advancedFilterIcon} />
                        <span className={styles.advancedFilterText}>Advanced filter</span>
                        {advancedFilterActive && <span className={styles.dot} />}
                    </Button>
                )}
                <GridToolbarDensitySelector placeholder="" sx={{ color: 'var(--color-primary)' }} />
                <GridToolbarColumnsButton placeholder="" sx={{ color: 'var(--color-primary)' }} />
                {csvExportEnabled && (
                    <GridToolbarExportContainer
                        placeholder=""
                        sx={{ color: 'var(--color-primary)' }}
                        disabled={!csvExportEnabled}
                    >
                        {csvExportEnabled && (
                            <GridCsvExportMenuItem
                                options={{
                                    fileName: `Wildlife Defense Platform_${title}`,
                                }}
                            />
                        )}
                        {excelExportEnabled && (
                            <GridExcelExportMenuItem
                                options={{
                                    fileName: `Wildlife Defense Platform_${title}`,
                                }}
                            />
                        )}
                        {pdfExportEnabled && (
                            <GridPrintExportMenuItem
                                options={{
                                    fileName: `Wildlife Defense Platform_${title}`,
                                }}
                            />
                        )}
                    </GridToolbarExportContainer>
                )}
            </div>
        </GridToolbarContainer>
    );
};

export default TableToolbar;
