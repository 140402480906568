import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/dashboards');
    }, []);

    return null;
};

const HomeRoute = {
    routeProps: {
        path: '/',
        element: <Home />,
    },
    name: 'Home',
};

export default HomeRoute;
