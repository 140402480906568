import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { tableHelpers } from '../../tableHelpers';

function formatKg(value: number | undefined | null) {
    if (value == null) {
        return '0kg';
    }
    return `${value.toFixed(2)}kg`;
}

function getRegion(params: GridValueGetterParams<string>) {
    return params.value || 'All';
}

const columns: GridColDef[] = [
    {
        field: 'species',
        aggregable: false,
    },
    {
        field: 'detail',
        aggregable: false,
    },
    {
        field: 'state',
        aggregable: false,
    },
    {
        field: 'region',
        headerName: 'Region',
        headerAlign: 'center',
        aggregable: false,
        width: 240,
        valueGetter: getRegion,
    },
    {
        field: 'seizureTotal',
        type: 'number',
        headerName: 'Seizures',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'itemTotal',
        type: 'number',
        headerName: 'Count',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'itemMean',
        type: 'number',
        headerName: 'Avg Count',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'itemMin',
        type: 'number',
        headerName: 'Min Count',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'itemMax',
        type: 'number',
        headerName: 'Max Count',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'weightKgTotal',
        type: 'number',
        headerName: 'Weight',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value, formatKg),
    },
    {
        field: 'weightKgMean',
        type: 'number',
        headerName: 'Avg. Weight',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value, formatKg),
    },
    {
        field: 'weightKgMin',
        type: 'number',
        headerName: 'Min Weight',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value, formatKg),
    },
    {
        field: 'weightKgMax',
        type: 'number',
        headerName: 'Max Weight',
        headerAlign: 'center',
        align: 'right',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value, formatKg),
    },
];

export default columns;
