import { gql } from '@apollo/client';

const UPDATE_USER_ADMIN_STATUS = gql`
mutation updateUserAdminStatus($user: UserInput!) {
    updateUserAdminStatus(user:$user){
      user {
            email
            isAdmin
            isSuperuser
            permissions {
                codename
            }
        }
    }      
  }
`;

export default UPDATE_USER_ADMIN_STATUS;