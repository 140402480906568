import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { Alert, IconButton, Snackbar, SnackbarProps, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Snackbar.module.css';

interface CustomSnackbarInterface extends SnackbarProps {
    message?: string;
    type: 'error' | 'success';
}

interface CustomSnackbarInterfaceProps extends CustomSnackbarInterface {
    onInit: (close: () => void) => void;
}

const STACK_SIZE = 5;

export const CustomSnackbar = ({
    type,
    onClose,
    className,
    autoHideDuration,
    message,
    key,
    onInit,
    ...props
}: CustomSnackbarInterfaceProps) => {
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(true);

    useEffect(() => {
        onInit(handleOnClose);
    }, []);

    const handleOnClose = () => {
        setSnackbarOpen(false);
        setTimeout(() => {
            // @ts-ignore
            onClose && onClose(null, '');
        }, 300);
    };

    return (
        <Snackbar
            {...props}
            key={key}
            open={snackbarOpen}
            className={className}
            onClose={(e, r) => {
                e.preventDefault();
                onClose && onClose(e, r);
                setSnackbarOpen(false);
            }}
        >
            <Alert
                style={{ alignItems: 'center' }}
                severity={type}
                sx={{
                    '& .MuiAlert-message': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <Typography variant="subtitle1">{message}</Typography>
                <IconButton size="small" color={type} aria-label="close" onClick={handleOnClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Alert>
        </Snackbar>
    );
};

const snackBar = () => {
    let exist = false;

    let parentContainer = document.querySelector(`.${styles.parentContainer}`) as HTMLElement;

    if (parentContainer) {
        exist = true;
    } else {
        parentContainer = document.createElement('div');
    }

    parentContainer.setAttribute('class', styles.parentContainer);
    if (!exist) {
        parentContainer.addEventListener('click', (e) => {
            e.stopPropagation();
        });
    }

    document.body.appendChild(parentContainer);

    const snackBars: Record<string, { el: HTMLElement; closeFunction: () => void }> = {};
    const stack: string[] = [];

    const deleteLast = () => {
        snackBars[stack[0]].closeFunction();
    };

    return (props: CustomSnackbarInterface) => {
        const id = uuid();
        stack.push(id);

        if (stack.length > STACK_SIZE) {
            deleteLast();
        }

        const container = document.createElement('div');
        snackBars[id] = { el: container, closeFunction: () => {} };
        parentContainer.prepend(container);
        const root = createRoot(container);

        const closeSnackBar = () => {
            stack.splice(stack.indexOf(id), 1);
            container.remove();
            delete snackBars[id];

            if (stack.length > STACK_SIZE) {
                deleteLast();
            }
        };

        let timeoutId = 0;
        root.render(
            <CustomSnackbar
                open={true}
                {...props}
                key={id}
                onInit={(close) => {
                    snackBars[id].closeFunction = close;
                }}
                onClose={(event, reason) => {
                    closeSnackBar();
                    props.onClose && props.onClose(event, reason);
                    clearTimeout(timeoutId);
                }}
                className={cn(styles.customSnackbar, props.className)}
            />
        );

        // @ts-ignore
        timeoutId = setTimeout(closeSnackBar, props.autoHideDuration ? props.autoHideDuration : 5000);
    };
};

export const openSnackBar = snackBar();
