import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './YesNoDialog.module.css';

export interface YesNoDialogProps {
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
    title: string | ReactNode;
    message: string | ReactNode;
}

export const YesNoDialog = ({ onConfirm, open, onClose, title, message }: YesNoDialogProps) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(open);

    useEffect(() => {
        setDialogOpen(open);
    }, [open]);

    const handleClose = () => {
        setDialogOpen(false);
        onClose();
    };

    const confirm = () => {
        onConfirm();
        handleClose();
    };

    return (
        <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="xs">
            <div className={styles.header}>
                {typeof title === 'string' ? <DialogTitle>{title}</DialogTitle> : title}
                <IconButton className={styles.closeIcon} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>
                <div className={styles.body}>
                    {typeof message === 'string' ? (
                        <Typography className={styles.question}>{message}</Typography>
                    ) : (
                        message
                    )}
                </div>

                <div className={styles.footer}>
                    <div className={styles.footerButtons}>
                        <Button color="primary" variant="outlined" onClick={handleClose}>
                            No
                        </Button>
                        <Button color="secondary" variant="contained" onClick={confirm}>
                            Yes
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
