import { Grid, Typography } from '@mui/material';
import cn from 'classnames';
import { IndictmentCharge } from '../../../../../types';
import { formatNullOrBoolean } from '../../../index';
import styles from '../../../CourtCaseDialog.module.css';

interface IndictmentChargeItemProps {
    item: IndictmentCharge;
}

export const IndictmentChargeItem = ({ item }: IndictmentChargeItemProps) => {
    const {
        charge,
        proposedCustodialMonths,
        proposedFine,
        proposedCommunityServiceHours,
        chargeWithdrawn,
        guiltyPlea,
        guiltyVerdict,
        isConcurrent
    } = item;

    return (
        <div className={styles.rowsBlockWrapper}>
            <Grid container className={cn(styles.row, styles.rowItemRow)}>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Legislation</Typography>
                    <Typography
                        className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}
                    >{`${charge?.legislation || ''} ${charge?.part || ''}`}</Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Plea</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {formatNullOrBoolean(guiltyPlea, 'Guilty', 'Not Guilty')}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Verdict</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {formatNullOrBoolean(guiltyVerdict, 'Guilty', 'Not Guilty')}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Withdrawn</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {formatNullOrBoolean(chargeWithdrawn, 'Yes', 'No')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={cn(styles.row, styles.rowItemRow)}>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Custodial sentence</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {`${ proposedCustodialMonths ? proposedCustodialMonths + ' months' : '--'}`}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Fine</Typography>
                    <Typography
                        className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}
                    >
                        {`${proposedFine ? proposedFine + 'MWK' : '--'}`}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Community service</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {`${proposedCommunityServiceHours ? proposedCommunityServiceHours + 'hours' : '--'}`}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Run</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {isConcurrent === null ? '--' : isConcurrent ? 'Concurrently' : 'Consecutively'}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};
