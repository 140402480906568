import { gql } from '@apollo/client';

const GET_COURT_CASE = gql`
  query courtCase($id: String!) {
    courtCase(id: $id) {
      id
      docket
      name
      courts {
        id
        name
        location {
          administrativeLevel1 {
            id
            name
          }
          region {
            id
            name
          }
        }
      }
      caseDurationDays
      isConcluded
      isConviction
      defenseAttorneyCount
      firstEvent {
        id
        date
        stage
      }
      latestEvent {
        id
        date
        stage
      }
      seizures {
        id
        date
        location {
          id
          name
          administrativeLevel1 { 
            id
            name
          }
        }
        apprehensionTeams {
          id
          name
          isLawEnforcement
        }
        seizedCommodities {
          id
          commodity {
            id
            name
          }
          protectionLevel
          count
          weightKg
          value
          origin
          destination
        }
        seizedAccessories {
          id
          accessory {
            id
            name
          }
          count
        }
      }
      indictments {
        arrestDate
        defendant {
          id
          firstName
          lastName
          birthdate
          nationality {
            id
            name
          }
          address
          defendantattorneySet {
            id
            defenseAttorney {
              id
              firstName
              lastName
            }
            lawFirm
            isPrivate
          }
        }
        indictmentCharges {
          id
          charge {
            legislation
            part
          }
          proposedCustodialMonths
          proposedFine
          chargeWithdrawn
          guiltyPlea
          guiltyVerdict
        }
        sentenceSet {
          fine
          suspendedFine
          custodialMonths
          suspendedMonths
          communityServiceHours
        }
        baileventSet {
          date
          bail
          bondAmount
          absconded
        }
        pleaBargain
        aggravatingFactors
        mitigatingFactors
      }
      hearings {
        id
        date
        dateAdjourned
        stage
        happened
        court {
          name
          location {
            id
            name
            administrativeLevel1 {
              id
              name
            }
            region {
              id
              name
            }
          }
        }
        judge {
          id
          firstName
          lastName
        }
        prosecutorappointmentSet {
          id
          prosecutor {
            id
            firstName
            lastName
          }
          agency
          isPrivate
        }
        defendantsPresent
        courtClerk
        notes
      }
    }
  }
`;

export default GET_COURT_CASE;