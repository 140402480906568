import React, { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Button } from '@c4ads/c4blocks';
import { List, ListItem } from '@mui/material';
import { DashboardItem } from '../../types/dashboards';
import { FullScreenLoader } from '../Loader/FullScreenLoader';
import { YesNoDialog } from '../YesNoDialog';
import styles from './DashboardsManagerDialog.module.css';

interface DashboardManagerDialogProps {
    dashboards: DashboardItem[];
}

export interface DashboardManagerRef {
    open: (onLoad: (dashboardItems: DashboardItem[]) => void, onDelete: (id: string) => void) => void;
}

interface ManagerRefInterface {
    onLoad: (dashboardItems: DashboardItem[]) => void;
    onDelete: (id: string) => void;
}

export const DashboardManagerDialog = forwardRef(
    ({ dashboards }: DashboardManagerDialogProps, ref: Ref<DashboardManagerRef | undefined>) => {
        const [dialogOpen, setDialogOpen] = useState<boolean>(false);
        const [dashboardsForLoad, setDashboardsForLoad] = useState<DashboardItem[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [dashboardDeleteDialogId, setDashboardDeleteDialogId] = useState<string | null>(null);

        const managerRef = useRef<ManagerRefInterface>({
            onLoad: (dashboardItems: DashboardItem[]) => {},
            onDelete: (id: string) => {},
        });

        useImperativeHandle(
            ref,
            () => {
                return {
                    open: (onLoad: (dashboardItems: DashboardItem[]) => void, onDelete: (id: string) => void) => {
                        setDialogOpen(true);

                        managerRef.current.onLoad = onLoad;
                        managerRef.current.onDelete = onDelete;
                    },
                };
            },
            []
        );

        const handleClose = () => {
            setDialogOpen(false);
        };

        const onDelete = async () => {
            if (!dashboardDeleteDialogId) {
                return;
            }

            setLoading(true);
            await managerRef.current.onDelete(dashboardDeleteDialogId);
            setLoading(false);
        };

        const addToLoadList = (item: DashboardItem) => {
            setDashboardsForLoad((prev) => {
                return [...prev, item];
            });
        };

        const removeFromToLoadList = (id: string) => {
            setDashboardsForLoad((prev) => {
                const index = prev.findIndex((item) => item.id === id);
                if (index !== -1) {
                    const clonePrev = [...prev];
                    clonePrev.splice(index, 1);
                    return clonePrev;
                } else {
                    return prev;
                }
            });
        };

        const onSave = () => {
            managerRef.current.onLoad(dashboardsForLoad);
            setDialogOpen(false);
        };

        return (
            <>
                <YesNoDialog
                    open={!!dashboardDeleteDialogId}
                    onClose={() => {
                        setDashboardDeleteDialogId(null);
                    }}
                    onConfirm={onDelete}
                    title={
                        <div className={styles.deleteDashboardTitleContainer}>
                            <DashboardIcon sx={{ fill: 'var(--text-color-primary)' }} />
                            <Typography variant="h6" className={styles.deleteDashboardTitle}>
                                Delete dashboard
                            </Typography>
                        </div>
                    }
                    message="Are you sure you want to delete this dashboard?"
                />
                <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="xs">
                    <DialogTitle>Load Dashboards</DialogTitle>
                    {loading && <FullScreenLoader className={styles.loader} />}
                    <DialogContent>
                        <List>
                            {dashboards.length === 0 && <ListItem>No dashboards found</ListItem>}
                            {dashboards.map((item) => (
                                <ListItem
                                    key={item.id}
                                    secondaryAction={
                                        <IconButton
                                            onClick={() => {
                                                setDashboardDeleteDialogId(item.id);
                                            }}
                                            edge="end"
                                            aria-label="delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemButton className={styles.checkbox}>
                                        <Checkbox
                                            checked={!!dashboardsForLoad.find((obj) => item.id === obj.id)}
                                            onChange={(e) => {
                                                e.target.checked ? addToLoadList(item) : removeFromToLoadList(item.id);
                                            }}
                                            color="secondary"
                                        />
                                    </ListItemButton>
                                    <ListItemText primary={item.title} className={styles.listText} />
                                </ListItem>
                            ))}
                        </List>
                        {dashboards.length > 0 && (
                            <div className={styles.footer}>
                                <Button color="secondary" variant="contained" onClick={onSave}>
                                    Load
                                </Button>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>
            </>
        );
    }
);
