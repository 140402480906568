import React, { Context, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { DataGrid } from '@c4ads/c4blocks';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useGridApiRef, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GET_USERS } from '../../../../../api/apollo/query';
import { UPDATE_USER_PERMISSION, UPDATE_USER_ADMIN_STATUS } from '../../../../../api/apollo/mutation';
import DELETE_USER from '../../../../../api/apollo/mutation/DeleteUser';
import { User } from '../../../../../types/user';
import {
    ChangePermissionsSettingsFunc,
    makeEditPermissionsButton,
    PermissionSettings,
    PermissionToggleCell,
} from '../superUserTableTools';
import { Loader } from '../../../../../components/Loader';
import { AddUserDialog } from '../components/AddUserDialog';
import { DeleteUserDialog } from '../components/DeleteUserDialog';
import { openSnackBar } from '../../../../../components/Snackbar';
import { AccountContext, AccountContextInterface } from '../../../AccountContext';
import { SuperUserOutletInterface } from '../index';
import styles from '../../../Account.module.css';
import superUserStyles from '../SuperUser.module.css';

const tablePermissions = {
    view_courtcase: {
        id: '96',
        name: 'Can view court case',
    },
    view_defendant: {
        id: '104',
        name: 'Can view defendant',
    },
    view_seizedcommodity: {
        id: '76',
        name: 'Can view seized commodity',
    },
    view_indictmentcharge: {
        id: '144',
        name: 'Can view indictment charge',
    },
    view_hearing: {
        id: '204',
        name: 'Can view hearing',
    },
    view_seizure: {
        id: '84',
        name: 'Can view seizure',
    },
    view_details: {
        id: '270',
        name: 'Can view court case details form',
    },
    can_export_tables: {
        id: '269',
        name: 'Can export spreadsheets from tables',
    },
    can_export_charts: {
        id: '270',
        name: 'Can export charts',
    },
};

const prepareColumns = (
    context: Context<PermissionSettings>,
    clickOnDelete: (userId: string) => void,
    editPermissions: (userId: string) => void,
    changePermissionsSettings: ChangePermissionsSettingsFunc
): GridColDef[] => {
    return [
        {
            field: 'name',
            type: 'string',
            headerName: 'Name',
            headerAlign: 'left',
            align: 'left',
            width: 240,
            groupable: false,
            renderCell: ({ value }) => (
                <Typography variant="body2" className={superUserStyles.label}>
                    {value}
                </Typography>
            ),
        },
        {
            field: 'email',
            type: 'string',
            headerName: 'Email',
            headerAlign: 'left',
            align: 'left',
            width: 240,
            groupable: false,
            renderCell: ({ value }) => (
                <Typography variant="body2" className={superUserStyles.label}>
                    {value}
                </Typography>
            ),
        },
        {
            field: 'editPermissions',
            headerName: '',
            width: 200,
            renderCell: (params) => {
                const Component = makeEditPermissionsButton(context, editPermissions);

                return <Component id={params.row.id} />;
            },
            disableColumnMenu: true,
            hideSortIcons: true,
        },
        {
            field: 'isAdmin',
            type: 'boolean',
            headerName: 'Admin',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.value}
                    codename="isAdmin"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'viewCourtCases',
            type: 'boolean',
            headerName: 'View Court Cases',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('view_courtcase')}
                    codename="view_courtcase"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'viewDetails',
            type: 'boolean',
            headerName: 'View Court Case Details',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('view_details')}
                    codename="view_details"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'viewDefendants',
            type: 'boolean',
            headerName: 'View Defendants',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('view_defendant')}
                    codename="view_defendant"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'viewSeizedCommodities',
            type: 'boolean',
            headerName: 'View Seized Commodities',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('view_seizedcommodity')}
                    codename="view_seizedcommodity"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'viewHearings',
            type: 'boolean',
            headerName: 'View Hearings',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('view_hearing')}
                    codename="view_hearing"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'viewCharges',
            type: 'boolean',
            headerName: 'View Charges',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('view_indictmentcharge')}
                    codename="view_indictmentcharge"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'viewSeizures',
            type: 'boolean',
            headerName: 'View Seizures',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('view_seizure')}
                    codename="view_seizure"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'canExportTables',
            type: 'boolean',
            headerName: 'Can Export Tables',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('can_export_tables')}
                    codename="can_export_tables"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'canExportCharts',
            type: 'boolean',
            headerName: 'Can Export Charts',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            groupable: false,
            renderCell: (params) => (
                <PermissionToggleCell
                    params={params}
                    checked={params.row.permissions.map((item) => item.codename).includes('can_export_charts')}
                    codename="can_export_charts"
                    changePermissionsSettings={changePermissionsSettings}
                />
            ),
        },
        {
            field: 'deleteUser',
            headerName: '',
            width: 144,
            renderCell: (params: GridRenderCellParams<User>) => (
                <Button
                    onClick={() => {
                        clickOnDelete(params.row.id);
                    }}
                    className={styles.deleteUserBtn}
                >
                    <DeleteOutlineIcon />
                </Button>
            ),
            disableColumnMenu: true,
            hideSortIcons: true,
        },
    ];
};

const UsersContext = createContext<PermissionSettings>({});

export const UsersTable = () => {
    const { user, search } = useOutletContext<SuperUserOutletInterface>();
    const { setTitle } = useContext<AccountContextInterface>(AccountContext);
    const [openUserInviteDialog, setOpenUserInviteDialog] = useState<boolean>(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    /*
        permissionsSettings -> {userId: {'codename': true | false}}
    */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [permissionsSettings, setPermissionsSettings] = useState<PermissionSettings>({});
    const [userIdForDelete, setUserIdForDelete] = useState<string | undefined>();
    const { data, loading: loadingUsers, refetch } = useQuery(GET_USERS, { skip: !(user.isAdmin || user.isSuperuser) });
    const [deleteUser] = useMutation(DELETE_USER);
    const [updateUserPermission] = useMutation(UPDATE_USER_PERMISSION);
    const [updateUserAdminStatus] = useMutation(UPDATE_USER_ADMIN_STATUS);

    const apiRef = useGridApiRef();

    useEffect(() => {
        setTitle(
            <UsersTableTitle
                user={user}
                openAddUserDialog={() => {
                    setOpenUserInviteDialog(true);
                }}
            />
        );
    }, [user, setOpenUserInviteDialog]);

    useEffect(() => {
        if (loadingUsers || !data) {
            return;
        }
        setUsers(
            data.users.filter((item) => {
                if (!item?.name) {
                    return false;
                }
                return search ? item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 : true;
            })
        );
    }, [loadingUsers, data, search]);

    const onDelete = (userId: string) => {
        setUserIdForDelete(userId);
        setOpenRemoveDialog(true);
    };

    const editPermissions = async (userId: string) => {
        setIsLoading(true);
        const userPermissionSettings = permissionsSettings[userId];
        const permissions = Object.keys(userPermissionSettings);

        let success: boolean = false;
        try {
            for (let i = 0; i < permissions.length; i++) {
                const codename = permissions[i];
                if (codename === 'isAdmin') {
                    await updateUserAdminStatus({ variables: { user: { id: userId } } });
                } else {
                    await updateUserPermission({ variables: { user: { id: userId }, codename } });
                }
            }
            success = true;
        } catch {
            openSnackBar({
                type: 'error',
                message: 'Server Error',
            });
        }

        setIsLoading(false);
        if (!success) {
            return;
        }

        setPermissionsSettings((prev) => {
            const clone = { ...prev };
            delete clone[userId];

            return clone;
        });

        setUsers(
            users.map((userObj) => {
                if (userObj.id === userId) {
                    return {
                        ...userObj,
                        isAdmin:
                            userPermissionSettings?.isAdmin !== undefined
                                ? userPermissionSettings?.isAdmin
                                : userObj.isAdmin,
                        permissions: permissions
                            .filter((permission) => permission !== 'isAdmin' && userPermissionSettings[permission])
                            .map((codename) => ({
                                codename,
                                id: tablePermissions[codename].id,
                                name: tablePermissions[codename].name,
                            })),
                    };
                } else {
                    return userObj;
                }
            })
        );
    };

    const changePermissionsSettings = (status: boolean, codeName: string, userId: string) => {
        setPermissionsSettings((prev) => {
            const userObj = users.find((item) => item.id === userId) as User;

            const permission =
                codeName !== 'isAdmin'
                    ? userObj?.permissions?.find((permission) => permission.codename === codeName)
                    : userObj.isAdmin;

            if ((permission && status) || (!permission && !status)) {
                const cloned = prev ? { ...prev } : { [userId]: {} };
                delete cloned[userId][codeName];
                return cloned;
            }

            return {
                ...prev,
                [userId]: { ...prev[userId], [codeName]: status },
            };
        });
    };

    const clickOnDeleteUser = async () => {
        const index = users.findIndex((user) => user.id === userIdForDelete);
        if (index !== -1) {
            const usersArrClone = [...users];
            const [userObj] = usersArrClone.splice(index, 1);

            setIsLoading(true);
            try {
                await deleteUser({ variables: { user: { id: userObj.id } } });
            } catch {
                openSnackBar({ type: 'error', message: 'Something went wrong.' });
            }

            setIsLoading(false);
            setUsers(usersArrClone);
        }
        setUserIdForDelete(undefined);
    };

    const onUpdateList = () => {
        refetch();
    };

    return (
        <UsersContext.Provider value={permissionsSettings}>
            <Box className={styles.tableBox}>
                {isLoading && (
                    <div className={styles.loaderContainer}>
                        <Loader />
                    </div>
                )}
                <DataGrid
                    className={superUserStyles.table}
                    apiRef={apiRef}
                    columns={prepareColumns(UsersContext, onDelete, editPermissions, changePermissionsSettings)}
                    rows={users || []}
                    checkboxSelection={false}
                    loading={loadingUsers}
                    hideFooterSelectedRowCount
                    hideFooterPagination
                />
            </Box>
            <AddUserDialog open={openUserInviteDialog} setOpen={setOpenUserInviteDialog} onUpdateList={onUpdateList} />
            <DeleteUserDialog
                deleteUserID={userIdForDelete}
                open={openRemoveDialog}
                setOpen={setOpenRemoveDialog}
                onClick={clickOnDeleteUser}
            />
        </UsersContext.Provider>
    );
};

const UsersTableTitle = ({ user, openAddUserDialog }: { user: User; openAddUserDialog: () => void }) => {
    const navigate = useNavigate();

    const back = () => {
        navigate('/account');
    };

    return (
        <Box>
            <Tooltip title="Back to Profile" placement="bottom-end">
                <div className={superUserStyles.backToMyAccountContainer} onClick={back}>
                    <IconButton>
                        <KeyboardBackspaceIcon />
                    </IconButton>

                    <Typography className={superUserStyles.backToMyAccountTitle} variant="body2" sx={{ m: 1 }}>
                        My Account
                    </Typography>
                </div>
            </Tooltip>
            <br />
            <div className={styles.titleContainer}>
                <div className={styles.titleInnerContainer}>
                    <Typography variant="h4" sx={{ m: 1 }}>
                        Users
                    </Typography>
                </div>

                {user.isAdmin && (
                    <Button color="secondary" className={styles.addBtn} onClick={openAddUserDialog}>
                        <AddCircleOutlineIcon sx={{ color: '#ffffff' }} />
                        Add user
                    </Button>
                )}
            </div>
        </Box>
    );
};
