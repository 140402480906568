import { useState, useCallback, useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Stack, Stepper, Step, StepLabel, styled } from '@mui/material';
import { Button } from '@c4ads/c4blocks';
import { startCase } from 'lodash';
import FilterFields from './FilterFields';
import VariableFields from './VariableFields';
import QueryPreview from './QueryPreview';
import { useAuth } from '../../api/auth';
import { ChartConfig } from '../../types/ChartConfig';
import VisualizationFields from './VisualizationFields';
import AppearanceFields from './AppearanceFields';
import AnnotationFields from './AnnotationFields';

export interface QueryFormProps {
    defaultValues: ChartConfig;
    handleSubmitChartConfig: (data: ChartConfig) => void;
}

const StyledStep = styled(Step)(() => ({
    '& .Mui-active': {
        color: '#646464 !important',
    },
    '& .MuiSvgIcon-root': {
        color: '#9e9e9e',
    },
    '& .Mui-completed': {
        color: '#122945 !important',
    },
}));

export default function QueryForm({ defaultValues, handleSubmitChartConfig }: QueryFormProps) {
    const { user } = useAuth();
    const [visualization, setVisualization] = useState<string | undefined>(() => {
        if (defaultValues.mark) {
            return defaultValues.mark === 'Choropleth' ? 'MAP' : 'CHART';
        }
    });

    const [activeStep, setActiveStep] = useState(() => {
        if (!defaultValues.id) {
            return 0;
        } else {
            switch (defaultValues.mark) {
                case 'Choropleth':
                    return 2;
                case 'Donut':
                    return 3;
                default:
                    return 4;
            }
        }
    });

    const [activeProductFilter, setActiveProductFilter] = useState(() => {
        if (defaultValues.filters.commodities.length > 0) {
            return 'COMMODITIES';
        }
        if (defaultValues.filters.species.length > 0) {
            return 'SPECIES';
        }
        if (defaultValues.filters.protectionLevels.length > 0) {
            return 'PROTECTION_LEVELS';
        }
        return 'COMMODITIES';
    });

    const [activeLocationFilter, setActiveLocationFilter] = useState(() => {
        if (defaultValues.filters.courts.length > 0) {
            return 'COURTS';
        }
        if (defaultValues.filters.administrativeLevel1.length > 0) {
            return 'ADMINISTRATIVE_LEVEL_1';
        }
        if (defaultValues.filters.regions.length > 0) {
            return 'REGIONS';
        }

        return 'ADMINISTRATIVE_LEVEL_1';
    });

    const { control, watch, getValues, setValue, handleSubmit } = useForm({
        defaultValues: defaultValues,
    });

    const selectedMark = watch('mark');

    const handleVisualization = useCallback(
        (value: string) => {
            setVisualization(value);
            if (value === 'MAP') {
                setValue('mark', 'Choropleth');
                setValue('groupby', null);
                setValue('filters.courts', []);
                setValue('filters.administrativeLevel1', []);
                setValue('filters.regions', []);
            }
            // setActiveStep(1);
        },
        [setValue]
    );

    const onSubmit: SubmitHandler<any> = (newConfig: ChartConfig) => {
        handleSubmitChartConfig({ ...newConfig });
    };

    const renderValue = useCallback(
        (value: unknown) => {
            let formatted = (value as string) || 'None';
            if (formatted == 'ADMINISTRATIVE_LEVEL_1') {
                formatted = user?.organization?.country?.administrativeLevelNames[1] || 'District';
            }
            return startCase(formatted.toLowerCase());
        },
        [user]
    );

    const isLastStep = useMemo(() => {
        let lastStep;
        if (visualization === 'CHART') {
            if (selectedMark === 'Donut') {
                lastStep = 3;
            } else {
                lastStep = 4;
            }
        }
        if (visualization === 'MAP') {
            lastStep = 2;
        }
        return activeStep === lastStep;
    }, [visualization, selectedMark, activeStep]);

    return (
        <Stack spacing={4}>
            <Stepper activeStep={activeStep}>
                <StyledStep>
                    <StepLabel>Select Visualization</StepLabel>
                </StyledStep>
                <StyledStep>
                    <StepLabel>Select Variables</StepLabel>
                </StyledStep>
                <StyledStep>
                    <StepLabel>Select Filters</StepLabel>
                </StyledStep>
                {visualization === 'CHART' && (
                    <StyledStep>
                        <StepLabel>Modify Appearance</StepLabel>
                    </StyledStep>
                )}
                {visualization === 'CHART' && selectedMark !== 'Donut' && (
                    <StyledStep>
                        <StepLabel>Add Annotation</StepLabel>
                    </StyledStep>
                )}
            </Stepper>
            {activeStep === 0 ? (
                <>
                    <VisualizationFields visualization={visualization} handleVisualization={handleVisualization} />
                    <Stack direction="row" justifyContent="flex-end">
                        <Button disabled={!visualization} onClick={() => setActiveStep((prev) => prev + 1)}>
                            Next
                        </Button>
                    </Stack>
                </>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="row" spacing={8} sx={{ minHeight: 400 }}>
                        <QueryPreview
                            watch={watch}
                            visualization={visualization}
                            getValues={getValues}
                            renderValue={renderValue}
                            activeStep={activeStep}
                        />
                        <div
                            style={{
                                width: 400,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Stack spacing={2}>
                                {activeStep === 1 && (
                                    <VariableFields
                                        control={control}
                                        setValue={setValue}
                                        renderValue={renderValue}
                                        watch={watch}
                                        visualization={visualization}
                                    />
                                )}
                                {activeStep === 2 && (
                                    <FilterFields
                                        control={control}
                                        setValue={setValue}
                                        renderValue={renderValue}
                                        watch={watch}
                                        visualization={visualization}
                                        activeProductFilter={activeProductFilter}
                                        setActiveProductFilter={setActiveProductFilter}
                                        activeLocationFilter={activeLocationFilter}
                                        setActiveLocationFilter={setActiveLocationFilter}
                                    />
                                )}
                                {activeStep === 3 && (
                                    <AppearanceFields
                                        visualization={visualization}
                                        control={control}
                                        setValue={setValue}
                                        renderValue={renderValue}
                                        watch={watch}
                                    />
                                )}
                                {activeStep === 4 && (
                                    <AnnotationFields
                                        visualization={visualization}
                                        control={control}
                                        setValue={setValue}
                                        renderValue={renderValue}
                                        watch={watch}
                                    />
                                )}
                            </Stack>
                        </div>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end">
                        {activeStep > 0 && <Button onClick={() => setActiveStep((prev) => prev - 1)}>Back</Button>}
                        {!isLastStep && <Button onClick={() => setActiveStep((prev) => prev + 1)}>Next</Button>}
                        {isLastStep && (
                            <Button type="submit" variant="contained" color="secondary">
                                Create
                            </Button>
                        )}
                    </Stack>
                </form>
            )}
        </Stack>
    );
}
