import { gql } from "@apollo/client";

const GET_ORGANIZATIONS = gql`
    query organizations {
        organizations {
            id
            name
            country {
                id
                name
            }
            trackedSpecies {
                id
                commonName
            }
        }
    }
`;

export default GET_ORGANIZATIONS;