import { Fragment, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { useQuery } from '@apollo/client';
import {
    useGridApiRef,
    GridRowParams,
    GridEventListener,
    GridFilterItem,
    GridFilterOperator,
    GridFilterInputValueProps,
} from '@mui/x-data-grid-premium';
import { TextField } from '@mui/material';
import GET_COURT_CASES from '../../../../api/apollo/query/GetCourtCases';
import columns from './columns';
import { useAuth } from '../../../../api/auth';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import TableToolbar from '../TableToolbar';
import CourtCaseDialog from '../../../../components/CourtCaseDialog';
import { AdvancedFilterComponent } from '../AdvancedFilter';
import { CourtCaseFilters } from '../../../../types/CourtCaseFilters';

const CommoditiesFilterInput = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        applyValue({ ...item, value: e.target.value });
    };

    return (
        <TextField
            InputLabelProps={{ shrink: true }}
            label="Value"
            placeholder="Filter value"
            autoFocus={true}
            variant="standard"
            onChange={handleOnChange}
        />
    );
};

const commoditiesFilters: GridFilterOperator[] = [
    {
        label: 'contains',
        value: 'contains',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }

            return (params): boolean => {
                return (
                    params.value.filter(
                        (item) => item.name.toLowerCase().indexOf(filterItem.value.toLowerCase()) !== -1
                    ).length > 0
                );
            };
        },
        InputComponent: CommoditiesFilterInput,
        getValueAsString: (value: number) => `${value} Stars`,
    },
];

const CourtCases = ({
    shrinkTitle,
    hideHeaders,
    showHeaders,
}: {
    shrinkTitle: boolean;
    hideHeaders: () => void;
    showHeaders: () => void;
}) => {
    const { user } = useAuth();
    const [rows, setRows] = useState<any[]>([]);
    const [advancedFilterOpen, setAdvancedFilterOpen] = useState<boolean>(false);
    const [advancedFilterActive, setAdvancedFilterActive] = useState<boolean>(false);

    const [openCourtCaseId, setOpenCourtCaseId] = useState<string | null>(null);

    const [filters, setFilters] = useState<CourtCaseFilters>({});

    const { loading } = useQuery(GET_COURT_CASES, {
        variables: { filters: filters },
        onCompleted: ({ courtCases }) => setRows(courtCases),
    });

    const apiRef = useGridApiRef();

    const girdColumns = useMemo(
        () =>
            columns.map((col) =>
                col.field === 'commodities'
                    ? {
                          ...col,
                          filterOperators: commoditiesFilters,
                      }
                    : col
            ),
        [columns]
    );

    useEffect(() => {
        setAdvancedFilterActive(Object.keys(filters).length !== 0);
    }, [filters]);

    useEffect(() => {
        const handleEvent: GridEventListener<'rowsScroll'> = (params, event, details) => {
            params.top > 5 ? hideHeaders() : showHeaders();
        };

        apiRef.current.subscribeEvent('rowsScroll', handleEvent);
    }, [apiRef, hideHeaders, showHeaders]);

    const onAdvancedFilterClick = () => {
        setAdvancedFilterOpen(true);
    };

    const canViewDetails = useMemo(() => {
        return user?.permissions?.map(({codename}) => codename).includes("view_details");
    }, [user]);

    return (
        <Fragment>
            <CustomDataGrid
                apiRef={apiRef}
                columns={girdColumns}
                rows={rows}
                onRowClick={(params: GridRowParams) => canViewDetails && setOpenCourtCaseId(params.row.id)}
                checkboxSelection={false}
                loading={loading}
                hideFooterSelectedRowCount
                hideFooterPagination
                components={{ Toolbar: TableToolbar }}
                componentsProps={{
                    toolbar: {
                        title: 'Court Cases',
                        showTitle: shrinkTitle,
                        onAdvancedFilterClick,
                        advancedFilterActive,
                        csvExportEnabled: user?.permissions?.map(({codename}) => codename).includes("can_export_tables"),
                    },
                }}
            />
            <AdvancedFilterComponent
                onFilters={setFilters}
                open={advancedFilterOpen}
                onOpenChange={setAdvancedFilterOpen}
            />
            <CourtCaseDialog openCourtCaseId={openCourtCaseId} setOpenCourtCaseId={setOpenCourtCaseId} />
        </Fragment>
    );
};

export default CourtCases;
