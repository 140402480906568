import { createContext, ReactNode } from 'react';

export interface AccountContextInterface {
    setTitle: (title: string | ReactNode) => void;
    setContainerMaxWidth: (maxWidth: 'md' | undefined) => void;
}

export const AccountContext = createContext<AccountContextInterface>({
    setTitle: () => {},
    setContainerMaxWidth: () => {},
});
