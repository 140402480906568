import { gql } from '@apollo/client';

const GET_DEFENDANTS =  gql`
    query indictments {
        indictments {
            id
            arrestDate
            courtCase {
                id
                docket
            }
            defendant {
                id
                name
                birthdate
                sex
                nationality {
                    id
                    name
                }
                address
            }
            recidivism
            baileventSet {
                id
                date
                bail
                bondAmount
                absconded
            }
            indictmentCharges {
                guiltyVerdict
            }
            pleaBargain
            outcomeDate
            sentenceSet {
                fine
                custodialMonths
                suspendedFine
                suspendedMonths
            }
            aggravatingFactors
            mitigatingFactors
        }
    }
`;

export default GET_DEFENDANTS;