import { gql } from '@apollo/client';

const GET_HEARINGS = gql`
    query hearings {
        hearings {
            id
            date
            dateAdjourned
            courtClerk
            defendantsPresent
            happened
            stage
            courtCase {
                docket
            }
            court {
                name
                tier
                location {
                    id
                    name
                    administrativeLevel1 {
                        id
                        name
                    }
                    region {
                        id
                        name
                    }
                }
            }
            judge {
                id
                firstName
                lastName
            }
            courtClerk
            defendantsPresent
            prosecutorappointmentSet {
                id
                prosecutor {
                    id
                    firstName
                    lastName
                }
                agency
                isPrivate
            }
            notes
        }
    }
`;

export default GET_HEARINGS;
