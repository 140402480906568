import { Box, Grid, ListItemText, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { listItemTextProps } from '../index';
import { CourtCase } from '../../../types';
import { stringFromArray } from '../../../helpers';
import styles from '../CourtCaseDialog.module.css';

interface HearingsComponentProps {
    courtCase: CourtCase | null;
}

export const HearingsComponent = ({ courtCase }: HearingsComponentProps) => {
    return (
        <>
            <Typography variant="h6" color="primary" className={cn(styles.title, styles.boxHeader)}>
                Hearings
                <div className={styles.boxHeaderUnderLineInnerContainer}>
                    <Typography className={styles.boxHeaderTextUnderline} variant="h6" color="primary">
                        Hearings
                    </Typography>
                    <div className={styles.boxHeaderUnderline} />
                </div>
            </Typography>
            {courtCase?.hearings &&
                courtCase.hearings.map((hearing) => {
                    const {
                        stage,
                        notes,
                        date,
                        dateAdjourned,
                        court,
                        judge,
                        courtClerk,
                        id,
                        happened,
                        prosecutorappointmentSet,
                        defendantsPresent,
                    } = hearing;

                    return (
                        <Box className={styles.wrapper} key={id}>
                            <Box className={styles.item}>
                                <Grid container className={styles.itemTitleBlock}>
                                    <Grid container>
                                        <Grid item xs={1.5}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Date: ${date ? DateTime.fromISO(date).toISODate() : '--'}`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Stage: ${stage || '--'}`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Location: ${court?.name || '--'}`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText
                                                className={styles.subtitle}
                                                primary={`Adjournment Date: ${
                                                    dateAdjourned ? DateTime.fromISO(dateAdjourned).toISODate() : '--'
                                                }`}
                                                {...listItemTextProps}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} className={styles.hearingsTitleEndContent}>
                                        {!happened && (
                                            <ListItemText
                                                className={cn(styles.subtitle, styles.hearingsEndContentSubtitle)}
                                                primary="Did not Happen"
                                                {...listItemTextProps}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Box className={cn(styles.tableHeaderContainer, styles.hearingTableHeaderContainer)}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography className={styles.tableText}>Magistrate</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={styles.tableText}>Court Clerk</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={styles.tableText}>Prosecutor(s)</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={styles.itemElements}>
                                    <Box className={styles.gridRow} key={id}>
                                        <Grid className={styles.gridRowInner} container>
                                            <Grid item xs={2}>
                                                <Typography className={styles.tableTextValue}>
                                                    {judge ?`${judge.firstName || ''} ${judge.lastName || ''} ${
                                                        judge.grade ? `(${judge.grade})` : ''
                                                    }`.trim() : '--'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={styles.tableTextValue}>{courtClerk || '--'}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography className={styles.tableTextValue}>
                                                    { prosecutorappointmentSet ? prosecutorappointmentSet
                                                      .filter((item) => !!item?.prosecutor)
                                                      .map((item) =>
                                                          stringFromArray(
                                                              [item.prosecutor.firstName, item.prosecutor.lastName],
                                                              ' '
                                                          )
                                                      )
                                                      .join(', ')
                                                    : '--'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box className={styles.defendantsFooter}>
                                    {/* <Box className={styles.defendantsFooterBlock}>
                                        <Typography className={cn(styles.tableText, styles.defendantsFooterTitle)}>
                                            Prosecutor(s):
                                        </Typography>
                                        <Typography className={cn(styles.tableTextValue, styles.additionalText)}>
                                            {prosecutorappointmentSet
                                                ? prosecutorappointmentSet
                                                      .filter((item) => !!item?.prosecutor)
                                                      .map((item) =>
                                                          stringFromArray(
                                                              [item.prosecutor.firstName, item.prosecutor.lastName],
                                                              ''
                                                          )
                                                      )
                                                      .join(', ')
                                                : '--'}
                                        </Typography>
                                    </Box> */}
                                    <Box className={styles.defendantsFooterBlock}>
                                        <Typography className={cn(styles.tableText, styles.defendantsFooterTitle)}>
                                            Defendants Present:
                                        </Typography>
                                        <Typography className={cn(styles.tableTextValue, styles.additionalText)}>
                                            {defendantsPresent}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={styles.itemFooter}>
                                <Box className={cn(styles.itemAdditionalInfo, styles.hearingsNotes)}>
                                    <Typography
                                        className={cn(
                                            styles.tableTextValue,
                                            styles.additionalText,
                                            styles.hearingsNotesText
                                        )}
                                    >
                                        <ListItemText
                                            className={cn(
                                                styles.subtitle,
                                                styles.itemAdditionalInfoTitle,
                                                styles.hearingsAdditionalInfoTitle
                                            )}
                                            primary="Additional Notes:"
                                            {...listItemTextProps}
                                        />
                                        {notes || '--'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
        </>
    );
};
