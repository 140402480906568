import { Text, View } from '@react-pdf/renderer';
import { Sentence } from '../../../../../types';
import { getWidthByXs } from '../../index';
import { styles } from '../../styles';

interface SentenceComponentProps {
    sentenceSet: Sentence[];
}

export const SentenceComponent = ({ sentenceSet }: SentenceComponentProps) => {
    return (
        <View style={styles.defendantsItem}>
            <View style={styles.defendantsHeader}>
                <View style={styles.defendantsTableTextBlock}>
                    <Text style={styles.defendantsTableText}>Sentence</Text>
                </View>
                <View style={styles.defendantsHeaderLine} />
            </View>
            {sentenceSet.map((item: Sentence, index: number) => {
                const { custodialMonths, fine, communityServiceHours, suspendedMonths } = item;
                return (
                    <View
                        key={index}
                        style={[
                            styles.rowsBlockWrapper,
                            index + 1 === sentenceSet.length ? styles.rowsBlockWrapperWithoutBorder : {},
                        ]}
                    >
                        <View style={[styles.tableRow, styles.tableRowWithoutBorder]}>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Custodial Sentence</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>{custodialMonths || '--'}</Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Fine</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>
                                    {fine ? fine + 'MWK' : '--'}
                                </Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Community Service</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>
                                    {communityServiceHours || '--'}
                                </Text>
                            </View>
                            <View style={[styles.cellItem, { width: `${getWidthByXs(3)}%` }]}>
                                <Text style={[styles.cellText, styles.contentHeaderText]}>Suspended Sentence</Text>
                                <Text style={[styles.cellText, styles.cellTextValue]}>{suspendedMonths || '--'}</Text>
                            </View>
                        </View>
                    </View>
                );
            })}
        </View>
    );
};
