import { gql } from '@apollo/client';

const GET_COMMODITIES = gql`
    query commodities{
        commodities {
            id
            species {
                id
                kingdom
                commonName
            }
            detail {
                id
                detail
            }
            state {
                id
                state
            }
        }
    }
`;

export default GET_COMMODITIES;