import { Grid, Typography } from '@mui/material';
import cn from 'classnames';
import { BailEvent } from '../../../../../types/BailEvent';
import { formatNullOrBoolean } from '../../../index';
import styles from '../../../CourtCaseDialog.module.css';
import { DateTime } from 'luxon'

interface BailEventItemProps {
    item: BailEvent;
}

export const BailEventItem = ({ item }: BailEventItemProps) => {
    const {
        date,
        bail,
        bondAmount,
        absconded,
    } = item;

    return (
        <div className={styles.rowsBlockWrapper}>
            <Grid container className={cn(styles.row, styles.rowItemRow)}>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Date</Typography>
                    <Typography
                        className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}
                    >
                        {date ? DateTime.fromISO(date).toISODate() : '--'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Bail</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {formatNullOrBoolean(bail, 'Yes', 'No (Remand in custody)')}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Bond</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {bondAmount ? `${bondAmount} MWK` : '--'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Absconded</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {formatNullOrBoolean(absconded, 'Yes', 'No')}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};
