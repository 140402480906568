import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { PDF_HEIGHT, PDF_WIDTH } from '../../../constants/local';
import { CourtCase, Indictment, SeizedCommodity, Seizure } from '../../../types';
import { stringFromArray } from '../../../helpers';
import { IndictmentComponent } from './Defendants/IndictmentComponent';
import { SentenceComponent } from './Defendants/SentenceComponent';
import { styles } from './styles';

interface CourtCaseDialogPdgProps {
    courtCase: CourtCase | null;
}

interface TableItemProps {
    title: string | number | null;
    xs?: number | null;
}

export const CourtCaseDialogPdf = ({ courtCase }: CourtCaseDialogPdgProps) => {
    return (
        <Document>
            <Page size={{ width: PDF_WIDTH * 2, height: PDF_HEIGHT * 2 }} style={styles.page}>
                <View style={styles.header}>
                    <Text style={styles.headerText}>Court Case Record</Text>
                </View>
                <View style={styles.itemContainer}>
                    <ItemHeader title="Case Details" />
                    <View style={styles.itemContent}>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>Docket</Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>{courtCase?.docket}</Text>
                        </View>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>Regions</Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>
                                {courtCase?.courts?.map(({ location }) => location.region?.name).join(', ')}
                            </Text>
                        </View>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>
                                Districts
                            </Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>
                                {courtCase?.courts
                                    ?.map(({ location }) => location.administrativeLevel1?.name)
                                    .join(', ')}
                            </Text>
                        </View>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>Courts</Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>
                                {courtCase?.courts?.map(({ name }) => name).join(', ')}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.itemContent}>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>
                                First Event
                            </Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>
                                {courtCase?.firstEvent
                                    ? `${courtCase.firstEvent.date} (${courtCase.firstEvent.stage})`
                                    : '--'}
                            </Text>
                        </View>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>
                                Latest Event
                            </Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>
                                {courtCase?.latestEvent
                                    ? `${courtCase.latestEvent.date} (${courtCase.latestEvent.stage})`
                                    : '--'}
                            </Text>
                        </View>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>
                                Case Status
                            </Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>
                                {courtCase?.isConcluded ? 'Concluded' : 'Ongoing'}
                            </Text>
                        </View>
                        <View style={[styles.itemContentCol, styles.itemContentCaseDetails]}>
                            <Text style={[styles.contentHeaderText, styles.contentHeaderTextCaseDetails]}>
                                Case Duration (Days)
                            </Text>
                            <Text style={[styles.cellText, styles.cellTextCaseDetails]}>
                                {courtCase?.caseDurationDays}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.itemContainer}>
                    <ItemHeader title="Seizure(s)" />
                    {courtCase?.seizures?.map(
                        ({ id, seizedCommodities, date, location, apprehensionTeams }: Seizure) => (
                            <View style={styles.itemWrapper}>
                                <View style={styles.itemBlock}>
                                    <View style={[styles.itemContent, styles.itemTitleBlock]}>
                                        <View
                                            style={[
                                                styles.itemContentCol,
                                                styles.seizureItemContentCol,
                                                { width: `${getWidthByXs(3)}%` },
                                            ]}
                                        >
                                            <Text style={[styles.subtitle]}>{`Date: ${
                                                date ? DateTime.fromISO(date).toISODate() : '--'
                                            }`}</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.itemContentCol,
                                                styles.seizureItemContentCol,
                                                { width: `${getWidthByXs(3)}%` },
                                            ]}
                                        >
                                            <Text style={[styles.subtitle]}>{`Location: ${
                                                location
                                                    ? location?.administrativeLevel1?.name ||
                                                      location?.administrativeLevel2?.name ||
                                                      '--'
                                                    : '--'
                                            }`}</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.table, styles.seizureTable]} key={id}>
                                        <View style={styles.tableHead}>
                                            <TableHeaderCell title="Protection Level" xs={2} />
                                            <TableHeaderCell title="Commodity" xs={2} />

                                            <TableHeaderCell title="Count" xs={1} />
                                            <TableHeaderCell title="Weight" xs={1} />

                                            <TableHeaderCell title="Origin" xs={2} />
                                            <TableHeaderCell title="Destination" xs={2} />
                                            <TableHeaderCell title="Value" xs={2} />
                                        </View>
                                        <View style={styles.tableBody}>
                                            {seizedCommodities.map(
                                                ({
                                                    id,
                                                    commodity,
                                                    count,
                                                    weightKg,
                                                    protectionLevel,
                                                    value,
                                                }: SeizedCommodity) => (
                                                    <View style={styles.tableRow} key={id}>
                                                        <TableCell title={protectionLevel || '--'} xs={2} />
                                                        <TableCell title={commodity.name || '--'} xs={2} />

                                                        <TableCell title={count} xs={1} />
                                                        <TableCell title={weightKg ? `${weightKg}kg` : '--'} xs={1} />
                                                        <TableCell title={value ? `${value}MWK` : '--'} xs={2} />

                                                        <TableCell title="--" xs={2} />
                                                        <TableCell title="" xs={2} />
                                                    </View>
                                                )
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.itemFooter}>
                                    <View style={[styles.itemAdditionalInfo]}>
                                        <Text style={[styles.subtitle]}>Apprehension Team: {`--`}</Text>
                                    </View>
                                    <View style={[styles.itemAdditionalInfo, styles.itemAdditionalInfoWithoutBorder]}>
                                        <Text style={[styles.subtitle]}>Seized Instrumentalities: {`--`}</Text>
                                    </View>
                                </View>
                            </View>
                        )
                    )}
                </View>
                <View style={styles.itemContainer}>
                    <ItemHeader title="Defendants" />
                    {courtCase?.indictments?.map(
                        ({
                            id,
                            defendant,
                            arrestDate,
                            indictmentCharges,
                            pleaBargain,
                            sentenceSet,
                        }: Indictment) => {
                            const { firstName, lastName, birthdate, sex, nationality, address } = defendant;

                            return (
                                <View>
                                    <View style={styles.itemBlock}>
                                        <View style={[styles.itemContent, styles.itemTitleBlock, styles.defendantItem]}>
                                            <View>
                                                <View style={[styles.itemContent, styles.itemTitleBlock]}>
                                                    <View
                                                        style={[
                                                            styles.itemContentCol,
                                                            styles.seizureItemContentCol,
                                                            { width: `${getWidthByXs(2.4)}%` },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[styles.subtitle]}
                                                        >{`${firstName} ${lastName}`}</Text>
                                                    </View>
                                                    <View
                                                        style={[
                                                            styles.itemContentCol,
                                                            styles.seizureItemContentCol,
                                                            { width: `${getWidthByXs(2.4)}%` },
                                                        ]}
                                                    >
                                                        <Text style={[styles.subtitle]}>{`Birthdate: ${
                                                            birthdate || '--'
                                                        }`}</Text>
                                                    </View>
                                                    <View
                                                        style={[
                                                            styles.itemContentCol,
                                                            styles.seizureItemContentCol,
                                                            { width: `${getWidthByXs(2.4)}%` },
                                                        ]}
                                                    >
                                                        <Text style={[styles.subtitle]}>{`Nationality: ${nationality
                                                            .map(({ name }) => name)
                                                            .join(', ')}`}</Text>
                                                    </View>
                                                    <View
                                                        style={[
                                                            styles.itemContentCol,
                                                            styles.seizureItemContentCol,
                                                            { width: `${getWidthByXs(2.4)}%` },
                                                        ]}
                                                    >
                                                        <Text style={[styles.subtitle]}>{`Arrest Date: ${
                                                            arrestDate || '--'
                                                        }`}</Text>
                                                    </View>
                                                    <View
                                                        style={[
                                                            styles.seizureItemContentCol,
                                                            styles.defendantInfoContainer,
                                                            { width: `${getWidthByXs(2.4)}%` },
                                                        ]}
                                                    >
                                                        <View style={styles.defendantInfo}>
                                                            <Text style={[styles.subtitle]}>Absconded</Text>
                                                        </View>
                                                        <View style={styles.defendantInfo}>
                                                            <Text style={[styles.subtitle]}>Plea Bargin</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>

                                            <View>
                                                <Text style={[styles.subtitle, styles.defendantItemText]}>{`Address: ${
                                                    address || '--'
                                                }`}</Text>
                                                <Text
                                                    style={[styles.subtitle, styles.defendantItemText]}
                                                >{`Lawyers: ${'--'}`}</Text>
                                            </View>
                                        </View>

                                        <IndictmentComponent indictmentCharges={indictmentCharges} />
                                        <SentenceComponent sentenceSet={sentenceSet} />
                                        <View style={styles.defendantFooter}>
                                            <View style={[styles.defendantFooterBlock]}>
                                                <Text
                                                    style={[
                                                        styles.subtitle,
                                                        styles.contentHeaderText,
                                                        styles.defendantFooterText,
                                                    ]}
                                                >
                                                    AGGRAVATING FACTORS(S) : {`--`}
                                                </Text>
                                            </View>
                                            <View style={[styles.defendantFooterBlock]}>
                                                <Text
                                                    style={[
                                                        styles.subtitle,
                                                        styles.contentHeaderText,
                                                        styles.defendantFooterText,
                                                    ]}
                                                >
                                                    MITIGATING FACTORS(S): {`--`}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            );
                        }
                    )}
                </View>

                <View style={styles.itemContainer}>
                    <ItemHeader title="Hearings" />
                    {courtCase?.hearings?.map(
                        (
                            { date, stage, judge, prosecutors, defenseAttorneys, defenseattorneyappointmentSet },
                            index
                        ) => (
                            <View style={[styles.itemWrapper, styles.itemWrapperWithMargin]}>
                                <View style={styles.itemBlock}>
                                    <View style={[styles.itemContent, styles.itemTitleBlock]}>
                                        <View
                                            style={[
                                                styles.itemContentCol,
                                                styles.seizureItemContentCol,
                                                { width: `${getWidthByXs(2)}%` },
                                            ]}
                                        >
                                            <Text style={[styles.subtitle]}>{`Date: ${
                                                date ? DateTime.fromISO(date).toISODate() : '--'
                                            }`}</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.itemContentCol,
                                                styles.seizureItemContentCol,
                                                { width: `${getWidthByXs(2)}%` },
                                            ]}
                                        >
                                            <Text style={[styles.subtitle]}>{`Stage: --`}</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.itemContentCol,
                                                styles.seizureItemContentCol,
                                                { width: `${getWidthByXs(2)}%` },
                                            ]}
                                        >
                                            <Text style={[styles.subtitle]}>{`Location: --`}</Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.itemContentCol,
                                                styles.seizureItemContentCol,
                                                { width: `${getWidthByXs(2)}%` },
                                            ]}
                                        >
                                            <Text style={[styles.subtitle]}>{`Adjournmenet Date: --`}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.itemContent}>
                                        <View style={styles.table}>
                                            <View style={styles.tableHead}>
                                                <TableHeaderCell title="Magistrate" xs={3} />
                                                <TableHeaderCell title="Court Clerk" xs={3} />
                                                <TableHeaderCell title="" xs={6} />
                                            </View>
                                            <View style={styles.tableBody}>
                                                <View
                                                    style={[styles.tableRow, styles.tableRowWithoutBorder]}
                                                    key={index}
                                                >
                                                    <TableCell title={'--'} xs={3} />
                                                    <TableCell title={'--'} xs={3} />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.itemFooter}>
                                    <View style={[styles.itemAdditionalInfo]}>
                                        <Text style={[styles.subtitle]}>
                                            Defense attorney(s):{' '}
                                            {defenseattorneyappointmentSet
                                                ? defenseattorneyappointmentSet
                                                      .filter((item) => !!item?.defenseAttorney)
                                                      .map((item) =>
                                                          stringFromArray(
                                                              [
                                                                  item.defenseAttorney.firstName,
                                                                  item.defenseAttorney.lastName,
                                                              ],
                                                              ''
                                                          )
                                                      )
                                                      .join(', ')
                                                : '--'}
                                        </Text>
                                    </View>
                                    <View style={[styles.itemAdditionalInfo, styles.itemAdditionalInfoWithoutBorder]}>
                                        <Text style={[styles.subtitle]}>Defendants Present: {`--`}</Text>
                                    </View>
                                </View>
                            </View>
                        )
                    )}
                </View>
            </Page>
        </Document>
    );
};

export const getWidthByXs = (xs: number | null) => {
    return 100 * ((xs || 12) / 12);
};

export const ItemHeader = ({ title, xs = null }: TableItemProps) => {
    return (
        <View style={[styles.itemHeaderWrapper, { width: `${getWidthByXs(xs)}%` }]}>
            <Text style={[styles.itemHeader, styles.itemHeaderContainer]}>{title}</Text>
            <View style={styles.boxHeaderUnderLineInnerContainer}>
                <Text style={[styles.itemHeader, styles.boxHeaderTextUnderline]}>{title}</Text>
                <View style={styles.boxHeaderUnderline} />
            </View>
        </View>
    );
};

export const TableCell = ({ title, xs = null }: TableItemProps) => {
    return (
        <View style={[styles.tableCell, { width: `${getWidthByXs(xs)}%` }]}>
            <Text style={styles.cellText}>{title}</Text>
        </View>
    );
};

export const TableHeaderCell = ({ title, xs = null }: TableItemProps) => {
    return (
        <View style={[styles.tableCell, { width: `${getWidthByXs(xs)}%` }]}>
            <Text style={styles.contentHeaderText}>{title}</Text>
        </View>
    );
};
