import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { LicenseInfo } from '@mui/x-license-pro';
import { theme } from '@c4ads/c4blocks';
import App from './App';
import apolloClient from './api/apollo';
import { AuthProvider } from './api/auth';
import './index.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const cache = createCache({
    key: 'css',
    prepend: true,
});

root.render(
    <React.StrictMode>
        <CacheProvider value={cache}>
            <React.StrictMode>
                <ApolloProvider client={apolloClient}>
                    <AuthProvider>
                        <ThemeProvider theme={theme}>
                            <App />
                        </ThemeProvider>
                    </AuthProvider>
                </ApolloProvider>
            </React.StrictMode>
        </CacheProvider>
    </React.StrictMode>
);
