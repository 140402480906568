import { createContext } from 'react';
import { DashboardItem } from '../../types/dashboards';
import { Task } from '../../hooks/useQueue';

interface DashboardsContextInterface {
    loadDashboards: (dashboards: { id: string; title: string }[]) => void;
    setIsLoading: (id: string, loading: boolean) => void;
    openDashboardManager: () => void;
    getDashboards: (includeRemote?: boolean) => DashboardItem[];
    addLocalStorageTask: (task: Task) => void;
    setSavedStatus: (id: string, status: boolean) => void;
    closeTab: (id: string) => void;
}

export const DashboardsContext = createContext<DashboardsContextInterface>({
    loadDashboards: (dashboards: { id: string; title: string }[]) => {},
    setIsLoading: (id: string, loading: boolean) => {},
    openDashboardManager: () => {},
    getDashboards: () => [],
    addLocalStorageTask: (task: Task) => {},
    setSavedStatus: (id: string, status: boolean) => {},
    closeTab: (id: string) => {},
});
