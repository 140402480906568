import { gql } from '@apollo/client';

const GET_FILTERS = gql`
    query User {
        user {
            advancedCourtCaseFilterConfigs {
                id
                title
            }
        }
    }
`;

export default GET_FILTERS;
