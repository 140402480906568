export const downloadBlob = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    a.click();
    a.remove();
    URL.revokeObjectURL(url);
};

export const stringFromArray = (arr: (string | undefined | null)[], separator: string): string => {
    const result = arr.filter((item) => !!item).join(separator);

    return result.length === 0 ? '--' : result;
};
