import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Input, Tab, Tabs } from '@mui/material';
import { AccountOutletContextInterface } from '../../index';
import { USERS_AND_GROUPS_LAST_VIEW } from '../../../../constants/sessionStorageConstants';
import { User } from '../../../../types/user';
import styles from '../../Account.module.css';
import superUserStyles from './SuperUser.module.css';
import { AccountContext } from '../../AccountContext';

const tabRoutes = {
    // groups: '/account/groups',
    users: '/account/users',
};

export interface SuperUserOutletInterface {
    user: User;
    search: string;
}

export const SuperUser = () => {
    const { user } = useOutletContext<AccountOutletContextInterface>();
    const { setContainerMaxWidth } = useContext(AccountContext);

    const [search, setSearch] = useState<string>('');
    const [activeView, setActiveView] = useState<string>('users');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setContainerMaxWidth(undefined);

        return () => {
            setContainerMaxWidth('md');
        };
    }, []);

    useEffect(() => {
        if (!(user.isSuperuser || user.isAdmin)) {
            navigate('/account', { replace: true });
        }

        if (location.pathname === '/account/groups' && !user.isSuperuser) {
            navigate('/account', { replace: true });
        }
    }, [user, location]);

    useEffect(() => {
        switch (location.pathname) {
            case '/account/users':
                setActiveView('users');
                break;
            case '/account/groups':
                setActiveView('groups');
                break;
        }
    }, [location]);

    const handleActiveView = (event: React.SyntheticEvent<Element, Event>, value: string) => {
        sessionStorage.setItem(USERS_AND_GROUPS_LAST_VIEW, tabRoutes[value]);
        navigate(tabRoutes[value]);
        setActiveView(value);
        setSearch('');
    };

    return (
        <>
            <br />
            <Box className={superUserStyles.tableHeader}>
                <Tabs
                    className={superUserStyles.tableTabs}
                    indicatorColor="secondary"
                    textColor="secondary"
                    value={activeView}
                    onChange={handleActiveView}
                >
                    {/* {user.isSuperuser && <Tab label="Groups" value="groups" />} */}
                    {(user.isSuperuser || user.isAdmin) && <Tab label="Users" value="users" />}
                </Tabs>
                <Input
                    className={styles.searchContainer}
                    placeholder={`Search ${activeView}...`}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    startAdornment={<SearchIcon className={superUserStyles.searchIcon} />}
                />
            </Box>

            <Outlet context={{ user, search }} />
        </>
    );
};
