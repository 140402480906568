import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import { Autocomplete } from '@c4ads/c4blocks';
import { useQuery } from '@apollo/client';
import { omit, capitalize } from 'lodash';
import { Commodity } from '../../types';
import { GET_COMMODITIES } from '../../api/apollo/query';
import styles from './filterFields.module.css';

export default function CommoditySelect({ user, control }) {
    const { data } = useQuery(GET_COMMODITIES, { skip: !user });

    const commodityOptions = useMemo(() => {
        if (data?.commodities) {
            return data.commodities.map(({ id, species, detail, state }) => ({
                id,
                species: omit(species, '__typename'),
                detail: omit(detail, '__typename'),
                state: omit(state, '__typename'),
            }));
        }
        return [];
    }, [data]);

    const getOptionLabel = ({ species, detail, state }: Commodity) => {
        const detailString = detail?.detail && detail.detail !== 'Animal' ? detail.detail : '';
        const stateString = state?.state ? `(${capitalize(state.state)})` : '';
        return `${species?.commonName || ''} ${detailString} ${stateString}`;
    };

    return (
        <Controller
            name="filters.commodities"
            control={control}
            render={({ field }) => (
                <Autocomplete
                    label={
                        <Typography variant="overline" color="#9e9e9e">
                            Commodities
                        </Typography>
                    }
                    className={styles.autocomplete}
                    placeholder="All"
                    autocompleteProps={{
                        multiple: true,
                        options: commodityOptions,
                        getOptionLabel: getOptionLabel,
                        groupBy: (option: Commodity) => option.species?.commonName || '',
                    }}
                    {...field}
                />
            )}
        />
    );
}
