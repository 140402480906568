import { useMemo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { pick, startCase } from 'lodash';
import { FormControl, Typography, Stack } from '@mui/material';
import { Autocomplete, DatePicker, ToggleButton, ToggleButtonGroup } from '@c4ads/c4blocks';
import CommoditySelect from './CommoditySelect';
import { useAuth } from '../../api/auth';
import { Court } from '../../types/Court';
import { Region } from '../../types/Region';
import { Species } from '../../types';
import styles from './filterFields.module.css';
import { useQuery } from '@apollo/client';
import GET_SPECIES from '../../api/apollo/query/GetSpecies';

const protectionLevelOptions = [
    'Endangered Species',
    'Listed Species',
    'Protected Species',
    'Game Species',
    'Forest Produce',
];

export default function FilterFields({
    visualization,
    control,
    setValue,
    renderValue,
    watch,
    activeProductFilter,
    setActiveProductFilter,
    activeLocationFilter,
    setActiveLocationFilter,
}) {
    const { user } = useAuth();

    const { data: speciesData } = useQuery(GET_SPECIES);

    const selectedX = watch('x');
    const selectedY = watch('y');

    const handleActiveProductFilter = useCallback(
        (event, value) => {
            setValue('filters.commodities', []);
            setValue('filters.species', []);
            setValue('filters.protectionLevels', []);
            setActiveProductFilter(value);
        },
        [setActiveProductFilter, setValue]
    );

    const handleActiveLocationFilter = useCallback(
        (event, value) => {
            setValue('filters.courts', []);
            setValue('filters.administrativeLevel1', []);
            setValue('filters.regions', []);
            setActiveLocationFilter(value);
        },
        [setActiveLocationFilter, setValue]
    );

    const administrativeLevelName = useMemo(() => {
        let adm1 = 'District';
        if (user?.organization.country) {
            adm1 = user.organization.country.administrativeLevelNames[1];
        }
        return adm1;
    }, [user]);

    const { courtOptions, administrativeLevel1Options, regionOptions } = useMemo(() => {
        let courtOptions: Pick<Court, 'id' | 'name'>[] = [];
        let administrativeLevel1Options: Pick<Region, 'id' | 'name'>[] = [];
        let regionOptions: Pick<Region, 'id' | 'name'>[] = [];

        if (user?.organization.country) {
            const { administrativeLevel1Regions, regions, courts } = user.organization.country;

            courtOptions = courts.map((court) => pick(court, ['id', 'name']));
            administrativeLevel1Options = administrativeLevel1Regions.map((adm1: Region) => pick(adm1, ['id', 'name']));
            regionOptions = regions.map((region: Region) => pick(region, ['id', 'name']));
        }
        return { courtOptions, administrativeLevel1Options, regionOptions };
    }, [user, selectedX]);

    const speciesOptions = useMemo(() => {
        if (speciesData) {
            return speciesData.species.map((species) => pick(species, ['id', 'commonName']));
        }
        return [];
    }, [speciesData]);

    const selectedStart = watch('filters.start');
    const selectedEnd = watch('filters.end');

    return (
        <>
            <FormControl>
                <Typography variant="overline" color="#9e9e9e">
                    Date Range
                </Typography>
                <Stack spacing={2} direction="row">
                    {selectedY !== 'OUTSTANDING_COURT_CASES' && (
                        <Controller
                            name="filters.start"
                            control={control}
                            render={({ field }) => (
                                <DatePicker label="Start" maxDate={selectedEnd} locale="en-mw" {...field} />
                            )}
                        />
                    )}
                    <Controller
                        name="filters.end"
                        control={control}
                        render={({ field }) => (
                            <DatePicker label="End" minDate={selectedStart} locale="en-mw" {...field} />
                        )}
                    />
                </Stack>
            </FormControl>
            <FormControl>
                <Typography variant="overline" color="#9e9e9e">
                    Product
                </Typography>
                <ToggleButtonGroup
                    color="primary"
                    size="small"
                    exclusive
                    fullWidth
                    value={activeProductFilter}
                    onChange={handleActiveProductFilter}
                >
                    <ToggleButton className={styles.toggleButton} value="COMMODITIES">
                        Commodity
                    </ToggleButton>
                    <ToggleButton className={styles.toggleButton} value="SPECIES">
                        Species
                    </ToggleButton>
                    <ToggleButton className={styles.toggleButton} value="PROTECTION_LEVELS">
                        Prot. Level
                    </ToggleButton>
                </ToggleButtonGroup>
                {activeProductFilter === 'COMMODITIES' && <CommoditySelect user={user} control={control} />}
                {activeProductFilter === 'SPECIES' && (
                    <Controller
                        name="filters.species"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                label={
                                    <Typography variant="overline" color="#9e9e9e">
                                        Species
                                    </Typography>
                                }
                                className={styles.autocomplete}
                                placeholder="All"
                                autocompleteProps={{
                                    multiple: true,
                                    options: speciesOptions,
                                    getOptionLabel: (option: Species) => option.commonName || '',
                                }}
                                {...field}
                            />
                        )}
                    />
                )}
                {activeProductFilter === 'PROTECTION_LEVELS' && (
                    <Controller
                        name="filters.protectionLevels"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                label={
                                    <Typography variant="overline" color="#9e9e9e">
                                        Protection Level
                                    </Typography>
                                }
                                className={styles.autocomplete}
                                placeholder="All"
                                autocompleteProps={{
                                    multiple: true,
                                    options: protectionLevelOptions,
                                    getOptionLabel: (option: string) => startCase(option.toLowerCase()),
                                }}
                                {...field}
                            />
                        )}
                    />
                )}
            </FormControl>
            {visualization === 'CHART' && (
                <FormControl>
                    <Typography variant="overline" color="#9e9e9e">
                        Location
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        size="small"
                        exclusive
                        fullWidth
                        value={activeLocationFilter}
                        onChange={handleActiveLocationFilter}
                    >
                        <ToggleButton className={styles.toggleButton} value="COURTS">
                            Court
                        </ToggleButton>
                        <ToggleButton className={styles.toggleButton} value="ADMINISTRATIVE_LEVEL_1">
                            {renderValue('ADMINISTRATIVE_LEVEL_1')}
                        </ToggleButton>
                        <ToggleButton className={styles.toggleButton} value="REGIONS">
                            Region
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {activeLocationFilter === 'COURTS' && (
                        <Controller
                            name="filters.courts"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    label={
                                        <Typography variant="overline" color="#9e9e9e">
                                            Courts
                                        </Typography>
                                    }
                                    className={styles.autocomplete}
                                    placeholder="All"
                                    autocompleteProps={{
                                        multiple: true,
                                        options: courtOptions,
                                        getOptionLabel: (option: Court) => option.name || '',
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    )}
                    {activeLocationFilter === 'ADMINISTRATIVE_LEVEL_1' && (
                        <Controller
                            name="filters.administrativeLevel1"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    label={
                                        <Typography variant="overline" color="#9e9e9e">
                                            {administrativeLevelName + 's'}
                                        </Typography>
                                    }
                                    className={styles.autocomplete}
                                    placeholder="All"
                                    autocompleteProps={{
                                        multiple: true,
                                        options: administrativeLevel1Options,
                                        getOptionLabel: (option: Region) => option.name || '',
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    )}
                    {activeLocationFilter === 'REGIONS' && (
                        <Controller
                            name="filters.regions"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    label={
                                        <Typography variant="overline" color="#9e9e9e">
                                            Regions
                                        </Typography>
                                    }
                                    className={styles.autocomplete}
                                    placeholder="All"
                                    autocompleteProps={{
                                        multiple: true,
                                        options: regionOptions,
                                        getOptionLabel: (option: Region) => option.name || '',
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    )}
                </FormControl>
            )}
        </>
    );
}
