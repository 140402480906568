import { Box, Grid, ListItemText, Typography } from '@mui/material';
import cn from 'classnames';
import { CourtCase } from '../../../types';
import { listItemTextProps } from '../index';
import styles from '../CourtCaseDialog.module.css';

interface CaseDetailsProps {
    courtCase: CourtCase | null;
}

export const CaseDetailsComponents = ({ courtCase }: CaseDetailsProps) => {
    return (
        <Box>
            <Typography variant="h6" color="primary" className={cn(styles.title, styles.boxHeader)}>
                Case Details
                <div className={styles.boxHeaderUnderLineInnerContainer}>
                    <Typography className={styles.boxHeaderTextUnderline} variant="h6" color="primary">
                        Case Details
                    </Typography>
                    <div className={styles.boxHeaderUnderline} />
                </div>
            </Typography>
            <Grid
                className={styles.caseDetailsContainer}
                container
                spacing={3}
                columns={4}
                sx={{ marginTop: 0, marginLeft: 0 }}
            >
                <Grid item xs={1}>
                    <ListItemText primary="Docket" secondary={courtCase?.docket} {...listItemTextProps} />
                </Grid>
                <Grid item xs={1}>
                    <ListItemText
                        primary="Regions"
                        secondary={courtCase?.courts?.map(({ location }) => location.region?.name).join(', ')}
                        {...listItemTextProps}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ListItemText
                        primary="Districts"
                        secondary={courtCase?.courts
                            ?.map(({ location }) => location.administrativeLevel1?.name)
                            .join(', ')}
                        {...listItemTextProps}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ListItemText
                        primary="Courts"
                        secondary={courtCase?.courts?.map(({ name }) => name).join(', ')}
                        {...listItemTextProps}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ListItemText
                        primary="First Event"
                        secondary={
                            courtCase?.firstEvent
                                ? `${courtCase.firstEvent.date} (${courtCase.firstEvent.stage})`
                                : '--'
                        }
                        {...listItemTextProps}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ListItemText
                        primary="Latest Event"
                        secondary={
                            courtCase?.latestEvent
                                ? `${courtCase.latestEvent.date} (${courtCase.latestEvent.stage})`
                                : '--'
                        }
                        {...listItemTextProps}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ListItemText
                        primary="Case Status"
                        secondary={courtCase?.isConcluded ? 'Concluded' : 'Ongoing'}
                        {...listItemTextProps}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ListItemText
                        primary="Case Duration (Days)"
                        secondary={courtCase?.caseDurationDays}
                        {...listItemTextProps}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
