import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Header, { HeaderContext } from './components/Header';
import Main from './components/Main';
import { routes, Login, ResetPassword } from './routes';
import ProtectedRoute from './components/ProtectedRoute';
import { RouteType } from './routes/types';
import { useAuth } from './api/auth';

function App() {
    const { isAuthenticated, isLoading } = useAuth();
    const [hide, setHide] = useState<boolean>(false);

    return (
        <HeaderContext.Provider value={{ hide, setHide }}>
            <BrowserRouter>
                <CssBaseline />
                <Header />
                <Main>
                    <Routes>
                        <Route key="Login" path="/login" element={<Login />} />
                        <Route key="RestorePassword" path="/reset-password" element={<ResetPassword />} />
                        <Route
                            element={
                                <ProtectedRoute
                                    isAuthenticated={isAuthenticated}
                                    isLoading={isLoading}
                                    // redirectTo='/login'
                                />
                            }
                        >
                            {routes.map(({ routeProps, name }: RouteType) => (
                                <Route key={name} {...routeProps} />
                            ))}
                        </Route>
                    </Routes>
                </Main>
            </BrowserRouter>
        </HeaderContext.Provider>
    );
}

export default App;
