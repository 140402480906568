import { useContext, useEffect, useState } from 'react';
import { MuiChipsInput } from 'mui-chips-input';
import { FilterComponentGeneralInterface } from '../FilterComponent';
import { FormControl, MenuItem, Select } from '@mui/material';
import { AdvancedFilterContext } from '../../AdvancedFilterContext';

export const FreeTextArrayInput = ({ field, onFilterFunction }: FilterComponentGeneralInterface) => {
    const { defaultFiltersValue } = useContext(AdvancedFilterContext);
    const [value, setValue] = useState<string[]>((defaultFiltersValue[field] as string[]) || []);

    useEffect(() => {
        onFilterFunction(field, field, () => ({
            [field]: value,
        }));
    }, [value]);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <FormControl size="small">
                <Select value="in">
                    <MenuItem value="in">in</MenuItem>
                </Select>
            </FormControl>
            <FormControl size="small">
                <MuiChipsInput
                    helperText={value.length > 0 ? 'Double click to edit a chip' : ''}
                    clearInputOnBlur
                    value={value}
                    size="small"
                    onChange={handleChange}
                />
            </FormControl>
        </>
    );
};
