import { gql } from '@apollo/client';

const GET_FILTER = gql`
    query advancedCourtCaseFilterConfig($id: String!) {
        advancedCourtCaseFilterConfig(id: $id) {
            id
            title
            config
        }
    }
`;

export default GET_FILTER;
