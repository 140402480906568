import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useOutletContext } from 'react-router';
import { Button } from '@c4ads/c4blocks';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
/*
import CreateOrganizationForm from '../CreateOrganizationForm';
import AddUserToOrganizationForm from '../AddUserToOrganizationForm';
 */
import { GET_USERS, GET_ORGANIZATIONS } from '../../../../api/apollo/query';
import { AccountOutletContextInterface } from '../../index';
import UserInviteForm from '../../../../components/UserInviteForm';
import { Organization } from '../../../../types/organization';
import UserTable from '../../../../components/UserTable';

export default function OrganizationManager() {
    const { user } = useOutletContext<AccountOutletContextInterface>();
    const [openUserInvite, setOpenUserInvite] = useState<boolean>(false);

    const { data: users, loading: loadingUsers, error: errorUsers } = useQuery(GET_USERS, { skip: !user.isAdmin });
    const {
        data: organizations,
        loading: loadingOrganizations,
        error: errorOrganizations,
    } = useQuery(GET_ORGANIZATIONS, { skip: !user.isSuperuser });

    return (
        <Box>
            <Button onClick={() => setOpenUserInvite(true)}>
                <AddIcon /> User
            </Button>
            <UserTable user={user} />
            <List>
                {organizations?.organizations.map((organization: Organization, i: number) => (
                    <ListItem key={i}>
                        <ListItemText primary={organization.name} secondary={organization.country.name} />
                    </ListItem>
                ))}
            </List>
            {/*
                {user?.isAdmin && <AddUserToOrganizationForm users={users} />}
            {user?.isSuperuser && <CreateOrganizationForm />}
                 */}

            <UserInviteForm open={openUserInvite} setOpen={setOpenUserInvite} />
        </Box>
    );
}
