import { gql } from '@apollo/client';

const CREATE_DASHBOARD_CONFIG = gql`
    mutation createDashboardConfig($dashboardConfig: DashboardConfigInput!) {
        createDashboardConfig(dashboardConfig: $dashboardConfig) {
            dashboardConfig {
                id
                title
            }
        }
    }
`;

export default CREATE_DASHBOARD_CONFIG;
