import React, { useContext, useEffect, useMemo } from 'react';
import { Button } from '@c4ads/c4blocks';
import { Outlet, Route, useNavigate, useOutletContext } from 'react-router';
import { styled, Avatar, Box, Typography, Grid, TextField } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import cn from 'classnames';
import FieldGrid from '../FieldGrid';
import { FieldItemProps } from '../FieldItem';
import { EditProfile } from './EditProfile';
import { User } from '../../../../types/user';
import { AccountOutletContextInterface } from '../../index';
import { AccountContext, AccountContextInterface } from '../../AccountContext';
import { USERS_AND_GROUPS_LAST_VIEW } from '../../../../constants/sessionStorageConstants';
import styles from './Profile.module.css';
import fieldItemStyles from '../FieldItem/FieldItem.module.css';

const UserAvatar = styled(Avatar)(({ theme }) => ({
    height: 80,
    width: 80,
    backgroundColor: theme.palette.primary.main,
}));

export const getUserRole = (user: User) => {
    if (user.isAdmin) {
        return 'Organization Admin';
    } else if (user.isSuperuser) {
        return 'Superuser';
    } else {
        return 'User';
    }
};

const ProfileContent = () => {
    const { user } = useOutletContext<AccountOutletContextInterface>();
    const { setTitle } = useContext<AccountContextInterface>(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        setTitle('My Profile');
    }, [user]);

    const usernameFields = (
        <Grid className={styles.profileTitleContainer} container spacing={2}>
            <Grid item sm={6} xs={12} md={6} xl={6}>
                <div className={styles.profileItem}>
                    <Typography variant="subtitle1" className={fieldItemStyles.editableFieldLabel}>
                        First name:
                    </Typography>
                    <TextField className={fieldItemStyles.editableField} value={user.firstName} disabled={true} />
                </div>
            </Grid>
            <Grid item sm={6} xs={12} md={6} xl={6}>
                <div className={styles.profileItem}>
                    <Typography variant="subtitle1" className={fieldItemStyles.editableFieldLabel}>
                        Last name:{' '}
                    </Typography>
                    <TextField className={fieldItemStyles.editableField} value={user.lastName} disabled={true} />
                </div>
            </Grid>
        </Grid>
    );

    const fields = useMemo(() => {
        let newFields: FieldItemProps[] = [];

        if (user) {
            newFields = [
                { render: usernameFields },
                { label: 'Email', name: 'email', value: user.email || '' },
                { label: 'Organization', name: 'organization', value: user.organization.name, editable: false },
                {
                    label: 'Role',
                    name: 'userType',
                    value: getUserRole(user),
                    editable: false,
                },
            ];
        }
        return newFields;
    }, [user]);

    const openEditProfile = () => {
        navigate('/account/edit-profile');
    };

    const openUsersAndGroups = () => {
        const lastView = sessionStorage.getItem(USERS_AND_GROUPS_LAST_VIEW);
        const url = !lastView
            ? '/account/users'
            : ['/account/users', '/account/groups'].includes(lastView)
            ? lastView
            : '/account/users';

        sessionStorage.setItem(USERS_AND_GROUPS_LAST_VIEW, url);
        navigate(url);
    };

    return (
        <>
            <Grid item className={styles.avatarWrapper}>
                <div className={styles.avatar}>
                    <UserAvatar />
                    <div className={styles.userName}>
                        <Typography variant="h6">{user.firstName}</Typography>
                        <Typography variant="h6">{user.lastName}</Typography>
                    </div>
                </div>
            </Grid>
            <div className={styles.accountInfoBlock}>
                <div className={styles.accountInfo}>
                    <PersonIcon />
                    <Typography variant="subtitle2">Account info</Typography>
                </div>
                {(user.isAdmin || user.isSuperuser) && (
                    <Button onClick={openUsersAndGroups} className={cn(styles.profileButton, styles.activeButton)}>
                        View Users
                    </Button>
                )}
            </div>

            <div className={styles.fieldsGrid}>
                <FieldGrid fields={fields} />
            </div>
            <Box className={styles.profileButtonsContainer}>
                <Button className={styles.profileButton} onClick={openEditProfile}>
                    Edit
                </Button>
            </Box>
        </>
    );
};
const Profile = () => {
    const { user } = useOutletContext<AccountOutletContextInterface>();

    if (user) {
        return (
            <Box className={styles.profileWrapper}>
                <Box className={styles.profileContainer}>
                    <Outlet context={{ user }} />
                </Box>
            </Box>
        );
    }
    return null;
};

export const ProfileRoute = {
    routeProps: {
        path: '',
        element: <Profile />,
        children: (
            <>
                <Route path="" element={<ProfileContent />} />
                <Route path="edit-profile" element={<EditProfile />} />
            </>
        ),
    },
    name: 'Profile',
};
