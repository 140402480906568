import { Grid } from '@mui/material';
import { Loader } from '../index';

interface FullScreenLoaderProps {
    className?: string;
}

export const FullScreenLoader = ({ className }: FullScreenLoaderProps) => {
    return (
        <Grid
            container
            className={className}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: '100%',
                position: 'fixed',
                top: 0,
                zIndex: 1,
                background: 'rgba(125,125,125,0.3)',
            }}
        >
            <Grid item>
                <Loader />
            </Grid>
        </Grid>
    );
};
