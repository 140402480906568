import { gql } from '@apollo/client';

const UPDATE_DASHBOARD_CONFIG = gql`
    mutation updateDashboardConfig($dashboardConfig: DashboardConfigInput!) {
        updateDashboardConfig(dashboardConfig: $dashboardConfig) {
            dashboardConfig {
                id
                title
            }
        }
    }
`;

export default UPDATE_DASHBOARD_CONFIG;
