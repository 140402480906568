import { gql } from '@apollo/client';

const GET_CHART_DATA = gql`
    query dataset($params: SummaryParams!) {
        dataset(params: $params) {
            title
            description
            markOptions
            data {
                key
                label
                data {
                    key
                    label
                    data
                    geometry
                }
            }
        }
    }
`;

export default GET_CHART_DATA;