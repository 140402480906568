import { styled } from '@mui/material';

export const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 360,
    gap: theme.spacing(2),
    padding: theme.spacing(4),
    margin: '0 auto',
}));
