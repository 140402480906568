import { useState, useImperativeHandle } from 'react';
import { DataGrid as DataGridComponent, Button } from '@c4ads/c4blocks';
import { GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { DataGridPremiumProps } from '@mui/x-data-grid-premium/models/dataGridPremiumProps';
import FilterListIcon from '@mui/icons-material/FilterList';
import styles from './CustomDataGrid.module.css';

interface CustomDataGridInterface extends DataGridPremiumProps {
    handleFilterModel?: (filterModel: GridFilterModel) => void;
    handleSortModel?: (sortModel: GridSortModel) => void;
    resetAll?: () => void;
}

export const CustomDataGrid = ({
    handleFilterModel,
    resetAll,
    handleSortModel,
    apiRef,
    ...props
}: CustomDataGridInterface) => {
    const ref = useGridApiRef();
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    useImperativeHandle(
        apiRef,
        () => {
            return ref.current;
        },
        [ref]
    );

    const onFilterModel = (filterModel: GridFilterModel) => {
        handleFilterModel && handleFilterModel(filterModel);
        ref.current.setFilterModel(filterModel);
        setFilterModel(filterModel);
    };

    const onSortModel = (sortModel: GridSortModel) => {
        handleSortModel && handleSortModel(sortModel);
        ref.current.setSortModel(sortModel);
        setSortModel(sortModel);
    };

    const resetAllFilters = () => {
        ref.current.setFilterModel({ items: [] });
        ref.current.setSortModel([]);

        setFilterModel({ items: [] });
        setSortModel([]);

        handleSortModel && handleSortModel([]);
        handleFilterModel && handleFilterModel({ items: [] });
    };

    return (
        <>
            <div className={styles.resetAllContainer}>
                {(filterModel?.items?.length !== 0 || sortModel?.length !== 0) && (
                    <>
                        <p>{filterModel ? filterModel?.items.length : 0} Filters Applied</p>
                        <span className={styles.resetAllDivider} />
                        <p>{sortModel ? sortModel.length : 0} Sorted Column(s)</p>
                        <span className={styles.resetAllDivider} />
                        <Button className={styles.resetAllButton} color="secondary" onClick={resetAllFilters}>
                            Reset all
                        </Button>
                    </>
                )}
            </div>
            <DataGridComponent
                apiRef={ref}
                {...props}
                components={{
                    ColumnFilteredIcon: (props) => {
                        return (
                            <div className={styles.columnFilteredIcon}>
                                <FilterListIcon fontSize={props.fondSize} className={props.className} />
                            </div>
                        );
                    },
                    ...props.components,
                }}
                onFilterModelChange={onFilterModel}
                onSortModelChange={onSortModel}
                componentsProps={{
                    panel: {
                        sx: {
                            '& .MuiDataGrid-filterForm': {
                                alignItems: 'flex-end',
                                gap: '8px',
                            },
                            '& .MuiFormControl-root': {
                                width: 'unset',
                            },
                            ...props.sx
                        },
                    },
                    ...props.componentsProps,
                }}
            />
        </>
    );
};
