import { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { DataGrid } from '@c4ads/c4blocks';
import {
    useGridApiRef,
    useKeepGroupedColumnsHidden,
    GridAggregationFunction,
    GridEventListener,
    GRID_AGGREGATION_FUNCTIONS,
    GridColDef,
    GridFilterModel,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import GET_SEIZED_COMMODITIES from '../../../../api/apollo/query/GetSeizedCommodities';
import { useAuth } from '../../../../api/auth';
import columns from './columns';
import { tableHelpers } from '../../tableHelpers';
import { CommoditiesTableToolBar } from './components/CommoditiesTableToolBar';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import CommoditiesTableColumnMenu from './components/CommoditiesTableColumnMenu';
import styles from './SeizedCommodities.module.css';

const SeizedCommodities = ({
    shrinkTitle,
    hideHeaders,
    showHeaders,
}: {
    shrinkTitle: boolean;
    hideHeaders: () => void;
    showHeaders: () => void;
}) => {
    const { user } = useAuth();
    const apiRef = useGridApiRef();
    const exportableTableApiRef = useGridApiRef();
    const [rows, setRows] = useState([]);

    const filters = useMemo(() => {
        return {
            start: undefined,
            end: undefined,
        };
    }, []);

    const { loading } = useQuery(GET_SEIZED_COMMODITIES, {
        variables: { params: { filters: filters } },
        skip: !user,
        onCompleted: ({ seizedCommodities }) => {
            /*
              here we add index to rowId to prevent key duplicate error
            */
            const clone = seizedCommodities.map((item, index) => ({ ...item, rowId: `${item.rowId}-${index}` }));
            return setRows(clone);
        },
    });

    const avgCommoditiesSeized: GridAggregationFunction<any, number | null> = {
        label: 'average by seizure',
        getCellValue: ({ row }) => ({ seizureTotal: row.seizureTotal, itemTotal: row.itemTotal }),
        apply: (params) => {
            let seizureTotal = 0;
            let itemTotal = 0;

            params.values.forEach((value) => {
                if (value) {
                    seizureTotal += value.seizureTotal;
                    itemTotal += value.itemTotal;
                }
            });

            return seizureTotal !== 0 ? Number((itemTotal / seizureTotal).toFixed(2)) : null;
        },
        columnTypes: ['number'],
    };

    const avgSeizureWeightKg: GridAggregationFunction<any, number | null> = {
        label: 'average seizure weight',
        getCellValue: ({ row }) => ({ seizureTotal: row.seizureTotal, weightKgTotal: row.weightKgTotal }),
        apply: (params) => {
            let seizureTotal = 0;
            let weightKgTotal = 0;

            params.values.forEach((value) => {
                if (value) {
                    seizureTotal += value.seizureTotal;
                    weightKgTotal += value.weightKgTotal;
                }
            });
            return seizureTotal !== 0 ? Number((weightKgTotal / seizureTotal).toFixed(2)) : null;
        },
        columnTypes: ['number'],
    };

    const tableInitialState = {
        rowGrouping: {
            model: ['species', 'detail', 'state'],
        },
        aggregation: {
            model: {
                seizureTotal: 'sum',
                itemTotal: 'sum',
                itemMean: 'avgCommoditiesSeized',
                itemMin: 'itemMin',
                itemMax: 'itemMax',
                weightKgTotal: 'sum',
                weightKgMean: 'avgSeizureWeightKg',
                weightKgMin: 'weightKgMin',
                weightKgMax: 'weightKgMax',
            },
        },
    };

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: tableInitialState,
    });

    const exportableTableInitialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            ...tableInitialState,
            rowGrouping: {
                model: [],
            },
        },
    });

    useEffect(() => {
        const handleEvent: GridEventListener<'rowsScroll'> = (params) => {
            params.top > 5 ? hideHeaders() : showHeaders();
        };

        return apiRef.current.subscribeEvent('rowsScroll', handleEvent);
    }, [apiRef, hideHeaders, showHeaders]);

    const aggregationFunctions = useMemo(() => {
        return {
            ...GRID_AGGREGATION_FUNCTIONS,
            avgCommoditiesSeized,
            avgSeizureWeightKg,
            itemMin: tableHelpers.aggregateCompareFunction('itemMin', 'min'),
            itemMax: tableHelpers.aggregateCompareFunction('itemMax', 'max'),
            weightKgMin: tableHelpers.aggregateCompareFunction('weightKgMin', 'min'),
            weightKgMax: tableHelpers.aggregateCompareFunction('weightKgMax', 'max'),
        };
    }, []);

    const getAggregationPosition = useCallback((groupNode) => (groupNode == null ? 'footer' : 'inline'), []);

    const processColumnsForExportableTable = (columns: GridColDef[]) => {
        const columnsClone = [...columns];
        const speciesIndex = columnsClone.findIndex((item) => item.field === 'species');
        const detailIndex = columnsClone.findIndex((item) => item.field === 'detail');
        const stateIndex = columnsClone.findIndex((item) => item.field === 'state');

        if (speciesIndex !== -1) {
            columnsClone[speciesIndex] = {
                ...columnsClone[speciesIndex],
                headerName: 'Species',
            };
        }
        if (detailIndex !== -1) {
            columnsClone[detailIndex] = {
                ...columnsClone[detailIndex],
                headerName: 'Commodity',
            };
        }
        if (stateIndex !== -1) {
            columnsClone[stateIndex] = {
                ...columnsClone[stateIndex],
                disableExport: true,
            };
        }

        return columnsClone;
    };

    const onExportCsv = () => {
        exportableTableApiRef.current.exportDataAsCsv({
            fileName: 'Wildlife Defense Platform_Seized Commodities',
        });
    };

    const onExportExcel = () => {
        exportableTableApiRef.current.exportDataAsExcel({
            fileName: 'Wildlife Defense Platform_Seized Commodities',
        });
    };

    const onFilterModel = (filterModel: GridFilterModel) => {
        exportableTableApiRef.current.setFilterModel(filterModel);
    };

    const onSortModel = (sortModel: GridSortModel) => {
        exportableTableApiRef.current.setSortModel(sortModel);
    };

    return (
        <>
            <CustomDataGrid
                apiRef={apiRef}
                columns={columns}
                getRowId={(row) => row.rowId}
                rows={rows}
                checkboxSelection={false}
                loading={loading}
                initialState={initialState}
                aggregationFunctions={aggregationFunctions}
                aggregationRowsScope="all"
                getAggregationPosition={getAggregationPosition}
                rowGroupingColumnMode="single"
                className={styles.dataGrid}
                handleFilterModel={onFilterModel}
                handleSortModel={onSortModel}
                groupingColDef={{
                    headerName: 'Commodity',
                    headerAlign: 'center',
                    width: 240,
                    hideDescendantCount: true,
                }}
                components={{
                    Toolbar: (props) => {
                        return (
                            <CommoditiesTableToolBar
                                {...props}
                                onExportCsv={onExportCsv}
                                onExportExcel={onExportExcel}
                            />
                        );
                    },
                    ColumnMenu: CommoditiesTableColumnMenu,
                }}
                componentsProps={{
                    toolbar: {
                        title: 'Seized Commodities',
                        showTitle: shrinkTitle,
                        csvExportEnabled: user?.permissions?.map(({codename}) => codename).includes("can_export_tables"),
                    },
                }}
                experimentalFeatures={{ aggregation: true }}
            />

            <div className={styles.exportableTable}>
                <DataGrid
                    apiRef={exportableTableApiRef}
                    columns={processColumnsForExportableTable(columns)}
                    getRowId={(row) => row.rowId}
                    rows={rows}
                    checkboxSelection={false}
                    loading={loading}
                    initialState={exportableTableInitialState}
                    aggregationFunctions={aggregationFunctions}
                    aggregationRowsScope="all"
                    getAggregationPosition={getAggregationPosition}
                    rowGroupingColumnMode="single"
                    disableRowGrouping={true}
                    experimentalFeatures={{ aggregation: true }}
                />
            </div>
        </>
    );
};

export default SeizedCommodities;
