import React, { ReactNode } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { ListItem, TextField, Typography, FormHelperText } from '@mui/material';
import styles from './FieldItem.module.css';

export interface FieldItemProps {
    name?: string;
    label?: string;
    value?: string;
    editable?: boolean;
    disabled?: boolean;
    control?: any;
    formProps?: any;
    render?: ReactNode;
    placeholder?: string;
}

export default function FieldItem({ label, value, editable, render, ...props }: FieldItemProps) {
    const component = () => {
        if (render) {
            return render;
        }

        return editable ? (
            <EditableField label={label} value={value} editable={editable} {...props} />
        ) : (
            <div className={styles.fieldContainer}>
                <Typography variant="subtitle1" className={styles.readonlyFieldLabel}>
                    {label}
                </Typography>
                <TextField className={styles.readonlyField} disabled={true} value={value} />
            </div>
        );
    };

    return <ListItem>{component()}</ListItem>;
}

export const EditableField = ({ disabled, control, name, label, formProps, placeholder }: FieldItemProps) => {
    const { errors } = useFormState({
        control,
    });

    return (
        <div className={styles.fieldContainer}>
            <Typography variant="subtitle1" className={styles.editableFieldLabel}>
                {label}
            </Typography>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TextField
                        className={styles.editableField}
                        disabled={disabled}
                        {...field}
                        placeholder={placeholder}
                    />
                )}
                {...formProps}
            />
            {name && errors[name]?.message && (
                <FormHelperText color="error">{errors[name]?.message as string}</FormHelperText>
            )}
        </div>
    );
};
