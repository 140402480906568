import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Avatar, AvatarGroup } from '@c4ads/c4blocks';
import { Tooltip } from '@mui/material';
import { Hearing } from '../../../../types/Hearing';
import { Judge } from '../../../../types/Judge';
import { Prosecutor } from '../../../../types/Prosecutor';
import { tableHelpers } from '../../tableHelpers';
import { uniq } from 'lodash';
// import { DataGridHearing } from './index';


const ProsecutorCell = ({ value }: { value: string[] }) => {

    return (
        <AvatarGroup max={4}>
            {value.map((v, i) => {
                return (
                    <Tooltip key={i} title={v}>
                        <Avatar>{v[0]}</Avatar>
                    </Tooltip>
                )})}
        </AvatarGroup>
    );
};

const AgencyCell = ({ value }: { value: string[] }) => {

    return (
        <AvatarGroup max={4}>
            {value.map((v, i) => {
                return (
                    <Tooltip key={i} title={v}>
                        <Avatar>{v[0]}</Avatar>
                    </Tooltip>
                )})}
        </AvatarGroup>
    );
};

const DefendantsCell = ({ value }: { value: string | null }) => {
    const defendants = value ? value.split(', ') : [];
    return (
        <AvatarGroup max={4}>
            {defendants.map((d, i) => {
                return (
                    <Tooltip key={i} title={d}>
                        <Avatar>{d[0]}</Avatar>
                    </Tooltip>
                )})}
        </AvatarGroup>
    );
};


const columns: GridColDef<Hearing>[] = [
    {
        field: 'docket',
        type: 'string',
        headerName: 'Docket',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.courtCase?.docket,
    },
    {
        field: 'date',
        type: 'date',
        headerName: 'Date',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'dateAdjourned',
        type: 'date',
        headerName: 'Date Adjourned',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'administrativeLevel1',
        type: 'string',
        headerName: 'District',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 200,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.court?.location?.administrativeLevel1?.name || '--',
    },
    {
        field: 'courtName',
        type: 'string',
        headerName: 'Court',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.court?.name,
    },
    {
        field: 'courtType',
        type: 'string',
        headerName: 'Court Type',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.court?.tier,
    },
    {
        field: 'stage',
        type: 'string',
        headerName: 'Stage',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 180,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'happened',
        type: 'boolean',
        headerName: 'Happened',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'judge',
        type: 'string',
        headerName: 'Magistrate',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => `${row.judge?.firstName} ${row.judge?.lastName} ${row.judge?.grade ? '(' + row.judge.grade + ')' : ''}`.trim(),
    },
    {
        field: 'courtClerk',
        type: 'string',
        headerName: 'Court Clerk',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
    },
    {
        field: 'defendantsPresent',
        type: 'string',
        headerName: 'Defendants Present',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 200,
        // renderCell: ({ value }) => <DefendantsCell value={value} />,
    },
    {
        field: 'prosecutorappointmentSet',
        type: 'string',
        headerName: 'Prosecutor(s)',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.prosecutorappointmentSet?.map(({ prosecutor }) => `${prosecutor.firstName} ${prosecutor.lastName}`.trim()).join(', '),
        // renderCell: ({ value }) => <ProsecutorCell value={value} />,
    },
    {
        field: 'prosecutorappointmentAgency',
        type: 'string',
        headerName: 'Prosecutor Agency',
        headerAlign: 'center',
        align: 'left',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<string>) => uniq(row.prosecutorappointmentSet?.map(({ agency }) => agency)).join(', '),
        // renderCell: ({ value }) => <AgencyCell value={value} />,
    },
    {
        field: 'notes',
        type: 'string',
        headerName: 'Notes',
        headerAlign: 'center',
        width: 200,
        groupable: false,
    },
];

export default columns;
