import { useMemo } from 'react';

export type Task = () => void;
type Callback = () => void;

function* stackFunction(
    stack: Task[] = [],
    startLoading: Callback,
    endLoading: Callback
): Generator<undefined, any, Task | undefined> {
    const task = yield;

    if (task) {
        stack.push(async () => {
            await task();
            const [, taskObj] = stack.splice(0, 2);

            if (taskObj) {
                await taskObj();
            } else {
                endLoading();
            }
        });

        if (stack.length === 1) {
            startLoading();
            stack[0]();
        }
    }
    // @ts-ignore
    yield* stackFunction(stack, startLoading, endLoading);
}

export const useQueue = (onStartLoading?: Callback, onEndLoading?: Callback) => {
    const stackGenerator = useMemo(() => {
        const stack = stackFunction(
            [],
            () => {
                onStartLoading && onStartLoading();
            },
            () => {
                onEndLoading && onEndLoading();
            }
        );
        stack.next();
        return stack;
    }, []);

    return {
        addTask: (task: () => void) => {
            stackGenerator.next(task);
        },
    };
};
