import { gql } from "@apollo/client";

const GET_HEARING_STAGES = gql`
query hearingStages {
    hearingStages {
      id
      stage
    }
  }
`;

export default GET_HEARING_STAGES;