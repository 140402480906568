import React, { useState } from 'react';
import { IconButton, TextField, Tooltip, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './EditableLabel.module.css';

interface EditableLabelProps {
    label: string;
    onChange: (value: string) => void;
    validator: (str: string) => { valid: boolean; message?: string };
    labelClassName?: string;
    inputClassName?: string;
}

export const EditableLabel = ({ label, onChange, validator, labelClassName, inputClassName }: EditableLabelProps) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [value, setValue] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

    const handleChange = (value: string) => {
        setValue(value);
    };

    const save = () => {
        if (!value) {
            setErrorMessage('Field cannot be empty');
            setTooltipOpen(true);
            return;
        } else if (!validator(value).valid) {
            setErrorMessage(validator(value)?.message || '');
            setTooltipOpen(true);
            return;
        }

        onChange(value);
        setEdit(false);
    };

    const cancel = () => {
        setEdit(false);
    };

    return (
        <>
            {edit ? (
                <Tooltip
                    title={errorMessage}
                    open={tooltipOpen}
                    onClose={() => {
                        setTooltipOpen(false);
                    }}
                >
                    <div className={styles.editContainer}>
                        <TextField
                            className={inputClassName}
                            onChange={(e) => {
                                handleChange(e.target.value);
                            }}
                            defaultValue={label}
                        />
                        <IconButton onClick={save}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton onClick={cancel}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip title="Double click to edit title." placement="top">
                    <Typography
                        onDoubleClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setEdit(true);
                        }}
                        variant="subtitle1"
                        className={labelClassName}
                    >
                        {label}
                    </Typography>
                </Tooltip>
            )}
        </>
    );
};
