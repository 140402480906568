import { GridColDef } from '@mui/x-data-grid-premium';
import { Commodity, Officer } from '../../../../types';
import { ApprehensionTeam } from '../../../../types/ApprehensionTeam';
import { tableHelpers } from '../../tableHelpers';
import { SeizureDataGrid } from './index';


const numberFormatter = (value: number | undefined): string => {
    if (value !== null && value !== undefined && value !== 0) {
        return value.toFixed(2);
    } else {
        return '';
    }
};

const columns: GridColDef<SeizureDataGrid>[] = [
    {
        field: 'docket',
        type: 'string',
        headerName: 'Docket',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'date',
        type: 'date',
        headerName: 'Date',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'location',
        type: 'string',
        headerName: 'Location',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'district',
        type: 'string',
        headerName: 'District',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'arrestingOfficers',
        type: 'string',
        headerName: 'Arresting Officers',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        valueGetter: ({ value }: { value: Officer[] }) =>
            value ? value.map((item) => (item.rank + ' ' + item.firstName + ' ' + item.lastName + (item.unit ? ' (' + item.unit.name + ')' : '')).trim()).join(', ') : '',
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'apprehensionTeams',
        type: 'string',
        headerName: 'Apprehension Teams',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        valueGetter: ({ value }: { value: ApprehensionTeam[] }) =>
            value ? value.map((item) => item.name).join(', ') : '',
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'seizedCommodityProtectionLevel',
        type: 'string',
        headerName: 'Protection Level',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 180,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'seizedCommodityCommodity',
        type: 'string',
        headerName: 'Commodity',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 200,
        valueGetter: ({ value }: { value?: Commodity }) => value?.name,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'seizedCommodityWeightKg',
        type: 'number',
        headerName: 'Weight (Kg)',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'seizedCommodityCount',
        type: 'number',
        headerName: 'Count',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'seizedCommodityValue',
        type: 'number',
        headerName: 'Value',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderCurrency(value),
    },
    {
        field: 'origin',
        type: 'string',
        headerName: 'Origin',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'destination',
        type: 'string',
        headerName: 'Destination',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
];

export default columns;