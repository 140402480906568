import HomeRoute from './Home';
/*
import AboutUsRoute from './AboutUs';
*/
import DashboardRoute from './Dashboards';
import SummaryTablesRoute from './SummaryTables';
import RegionalTrendsRoute from './RegionalTrends';
import AccountRoute from './Account';
import Login from './Login';
import ResetPassword from './ResetPassword';

const routes = [HomeRoute, DashboardRoute, SummaryTablesRoute, RegionalTrendsRoute, AccountRoute];

export { routes, Login, ResetPassword };
