import { SetupInterface } from './courtCasesSetup';
import { AdvancedFilterContextInterface } from '../AdvancedFilterContext';
import { FilterFunction } from '../index';

export const addComponentToField = <T,>(
    setup: Record<string, SetupInterface>,
    currentOperator: string,
    Component: (props: any) => JSX.Element,
    additionalProps?: T
) => {
    Object.entries(setup).forEach(([field, { operator }]) => {
        if (operator !== currentOperator) {
            return;
        }

        if (setup[field].getComponent !== undefined) {
            return;
        }
        setup[field].getComponent = (
            context: AdvancedFilterContextInterface,
            onFilterFunction: (prevField: string, field: string, func: FilterFunction) => void
        ) => {
            return <Component {...additionalProps} onFilterFunction={onFilterFunction} field={field} />;
        };
    });
};
