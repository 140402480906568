import { useParams } from 'react-router-dom';
import { styled, Box, BoxProps, Typography, Collapse, GlobalStyles } from '@mui/material';
import { startCase } from 'lodash';
import useSummaryTables from './useSummaryTables';
import SeizedCommodities from './components/SeizedCommodities';
import CourtCases from './components/CourtCases';
import Defendants from './components/Defendants';
import Seizures from './components/Seizures';
import Hearings from './components/Hearings';
import Charges from './components/Charges';

interface TableWrapperProps extends BoxProps {
    expand?: boolean;
}

const TableWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'expand',
})<TableWrapperProps>(({ expand, theme }) => ({
    margin: `0 auto`,
    width: '100%',
    height: 'calc(100vh - 92px)',
    transition: theme.transitions.create(['height'], {
        duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up('xl')]: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
        height: '100%',
    },
    ...(expand && {
        height: '100vh',
    }),
}));

const SummaryTables = () => {
    const { description, shrinkTitle, hideHeaders, showHeaders } = useSummaryTables();
    const { table } = useParams();

    return (
        <>
            <GlobalStyles
                styles={{
                    '.MuiDataGrid-menuList': {
                        '& .MuiMenuItem-root': {
                            color: 'var(--color-primary)',
                            '& .MuiSvgIcon-root': {
                                fill: 'var(--color-primary)',
                            },
                        },
                    },
                    '& .MuiDataGrid-panelFooter': {
                        '& .MuiButton-root': {
                            color: 'var(--color-primary)',
                        },
                    },
                    '& .MuiDataGrid-columnsPanel': {
                        '& .MuiFormControlLabel-label': {
                            color: 'var(--color-primary)',
                        },
                        '& .MuiSwitch-root .Mui-checked': {
                            color: 'var(--color-primary)',
                        },
                    },
                }}
            />
            <Collapse appear={false} in={!shrinkTitle} collapsedSize={0}>
                <Box sx={{ textAlign: 'center', p: 4, pb: 2 }}>
                    <Typography variant="h6" color="textPrimary">
                        {startCase(table)}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {description}
                    </Typography>
                </Box>
            </Collapse>
            <TableWrapper expand={shrinkTitle}>
                {table === 'court-cases' && (
                    <CourtCases shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'defendants' && (
                    <Defendants shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'seized-commodities' && (
                    <SeizedCommodities shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'seizures' && (
                    <Seizures shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'hearings' && (
                    <Hearings shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'charges' && (
                    <Charges shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
            </TableWrapper>
        </>
    );
};

const SummaryTablesRoute = {
    routeProps: {
        path: '/summary-tables/:table',
        element: <SummaryTables />,
    },
    name: 'Summary Tools',
};

export default SummaryTablesRoute;
