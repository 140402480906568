import { gql } from '@apollo/client';

const GET_SEIZURES = gql`
    query seizures {
        seizures {
            id
            date
            courtCases {
                id
                docket
            }
            location {
                id
                name
                administrativeLevel1 {
                    id
                    name
                }
            }
            arrestingOfficers {
                id
                firstName
                lastName
                rank
                unit {
                    id
                    name
                }
            }
            apprehensionTeams {
                id
                name
                isLawEnforcement
            }
            seizedCommodities {
                id
                commodity {
                    id
                    name
                }
                origin
                destination
                protectionLevel
                count
                weightKg
                value
            }
        }
    }
`;

export default GET_SEIZURES;
