import { gql } from '@apollo/client';

const GET_SEIZED_COMMODITIES =  gql`
    query seizedCommodities($params: SummaryParams) {
        seizedCommodities(params: $params) {
            rowId
            species
            detail
            state
            region
            seizureTotal
            itemTotal
            itemMin
            itemMean
            itemMax
            weightKgTotal
            weightKgMin
            weightKgMean
            weightKgMax
        }
    }
`;

export default GET_SEIZED_COMMODITIES;