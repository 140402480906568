import React, { SyntheticEvent, useContext, useEffect, useState, useMemo, useRef } from 'react';
import { FormControl, IconButton, Autocomplete, TextField } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CourtCaseFilters } from '../../../../../types/CourtCaseFilters';
import { AdvancedFilterContext } from '../AdvancedFilterContext';
import { FilterFunction } from '../index';
import { CourtCasesSetup, SetupInterface } from '../setups/courtCasesSetup';
import styles from './FilterComponent.module.css';

export interface FilterComponentGeneralInterface {
    onFilterFunction: (prevField: string, newField: string, filterFunction: FilterFunction) => void;
    field: keyof CourtCaseFilters;
}

export interface FilterComponentProps {
    field: keyof CourtCaseFilters;
    onFilterFunction: (prevField: string, newField: string, filterFunction?: FilterFunction) => void;
    onRemove: () => void;
}

interface AutocompleteOption extends SetupInterface {
    field: string;
}

export const FilterComponent = ({ field, onFilterFunction, onRemove }: FilterComponentProps) => {
    const context = useContext(AdvancedFilterContext);
    const { availableFields } = context;
    const [fields, setFields] = useState<string[]>([...availableFields, field]);
    const [currentField, setCurrentField] = useState<string>(field);
    const [prevField, setPrevField] = useState<string>(field);
    const filterFunction = useRef<FilterFunction | undefined>(undefined);

    useEffect(() => {
        setFields([...availableFields, currentField]);
    }, [availableFields]);

    useEffect(() => {
        onFilterFunction(prevField, currentField, filterFunction.current);
    }, [currentField, prevField]);

    const filterComponent = useMemo(() => {
        if (CourtCasesSetup?.[currentField]?.getComponent !== undefined) {
            // @ts-ignore
            return CourtCasesSetup[currentField].getComponent(context, (pField, field, func) => {
                filterFunction.current = func;

                onFilterFunction(pField, field, func);
            });
        }
    }, [currentField, context]);

    const prepareFields = (currentAvailableFields: string[]): AutocompleteOption[] => {
        return currentAvailableFields.map((key) => ({
            field: key,
            ...CourtCasesSetup[key],
        }));
    };

    const prepareCurrentOption = (field): AutocompleteOption => {
        return {
            field,
            ...CourtCasesSetup[field],
        };
    };

    const handleChange = (e: SyntheticEvent<Element, Event>, option: AutocompleteOption | null) => {
        if (!option) {
            return;
        }
        setPrevField(currentField);
        setCurrentField(option.field);
    };

    return (
        <div className={styles.filterContainer}>
            <FormControl size="small">
                <Autocomplete
                    options={prepareFields(fields)}
                    groupBy={(option: AutocompleteOption) => option.groupName}
                    getOptionLabel={({ label }) => label}
                    renderInput={(value) => <TextField {...value} />}
                    onChange={handleChange}
                    value={prepareCurrentOption(currentField)}
                />
            </FormControl>
            {filterComponent}
            <IconButton className={styles.deleteButton} onClick={onRemove}>
                <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
        </div>
    );
};
