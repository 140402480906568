import { Grid, Typography } from '@mui/material';
import cn from 'classnames';
import { Sentence } from '../../../../../types';
import styles from '../../../CourtCaseDialog.module.css';

interface SentenceItemProps {
    item: Sentence;
}

export const SentenceItem = ({ item }: SentenceItemProps) => {
    const { custodialMonths, suspendedMonths, fine, suspendedFine, communityServiceHours, isConcurrent } = item;
    return (
        <Grid container className={styles.rowsBlockWrapper}>
            <Grid container className={styles.row}>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Custodial Sentence</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {custodialMonths || '--'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Fine</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {fine ? fine + 'MWK' : '--'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Community Service</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {communityServiceHours || '--'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={styles.cellItem}>
                    <Typography className={styles.tableText}>Suspended Sentence</Typography>
                    <Typography className={cn(styles.tableText, styles.tableTextValue, styles.defendantTableTextValue)}>
                        {suspendedMonths || '--'}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
