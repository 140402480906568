import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { Avatar, AvatarGroup } from '@c4ads/c4blocks';
import { Tooltip } from '@mui/material';
import { tableHelpers } from '../../tableHelpers';
import { some } from 'lodash';
import { IndictmentCharge } from '../../../../types';

const FactorCell = ({ factorsString = '' }: { factorsString: string }) => {
    const values = factorsString.replaceAll("'", '').replaceAll('[', '').replaceAll(']', '').split(', ').filter((v) => v !== '');
    return values.length > 0 ?
    (
    <AvatarGroup max={4}>
        {values.map((value, i) => (
            <Tooltip key={i} title={value}>
                <Avatar>{value.trim().slice(0, 1)}</Avatar>
            </Tooltip>
        ))}
    </AvatarGroup>
    ) : null;
};

const columns: GridColDef[] = [
    {
        field: 'courtCase',
        headerName: 'Docket',
        headerAlign: 'center',
        width: 144,
        groupable: false,
        valueGetter: ({ row }) => row.courtCase?.docket || '--',
    },
    {
        field: 'arrestDate',
        type: 'date',
        headerName: 'Arrest Date',
        headerAlign: 'center',
        align: 'center',
        width: 144,
        groupable: false,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'name',
        headerName: 'Name',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.defendant?.name || '',
    },
    {
        field: 'birthdate',
        type: 'date',
        headerName: 'Date of Birth',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.defendant?.birthdate,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'sex',
        headerName: 'Sex',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.defendant?.sex,
    },
    {
        field: 'nationality',
        headerName: 'Nationality',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<string>) =>
            row.defendant?.nationality.map(({ name }) => name || '').join(', '),
    },
    {
        field: 'address',
        type: 'string',
        headerName: 'Address',
        headerAlign: 'left',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.defendant.address,
    },
    {
        field: 'baileventSet',
        headerName: 'Bail',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<boolean>) => {
            return row.baileventSet.length === 0 ? null : row.baileventSet.some(({ bail }) => bail === true);
        },
        renderCell: ({ value }: GridRenderCellParams) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'pleaBargain',
        type: 'boolean',
        headerName: 'Plea Bargain',
        headerAlign: 'center',
        width: 100,
        groupable: false,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'absconded',
        type: 'boolean',
        headerName: 'Absconded',
        headerAlign: 'center',
        width: 100,
        groupable: false,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'outcomeDate',
        type: 'date',
        headerName: 'Outcome Date',
        headerAlign: 'center',
        align: 'center',
        width: 144,
        groupable: false,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'custodialMonths',
        type: 'number',
        headerName: 'Custodial Sentence (Months)',
        headerAlign: 'center',
        valueGetter: ({ row }: GridValueGetterParams<number>) =>
            row.sentenceSet?.map(({ custodialMonths }) => custodialMonths || 0)[0],
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
        groupable: false,
        width: 144,
    },
    {
        field: 'fine',
        type: 'number',
        headerName: 'Fine (MWK)',
        headerAlign: 'center',
        valueGetter: ({ row }: GridValueGetterParams<number>) => row.sentenceSet?.map(({ fine }) => fine || 0)[0],
        renderCell: ({ value }) => tableHelpers.renderCurrency(value),
        groupable: false,
        valueFormatter: ({ value }) => tableHelpers.currencyFormatter(value),
        width: 144,
    },
    {
        field: 'communityServiceHours',
        type: 'number',
        headerName: 'Community Service (Hours)',
        headerAlign: 'center',
        valueGetter: ({ row }: GridValueGetterParams<number>) =>
            row.sentenceSet?.map(({ communityServiceHours }) => communityServiceHours || 0)[0],
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
        groupable: false,
        width: 144,
    },
    {
        field: 'suspendedMonths',
        type: 'number',
        headerName: 'Suspended Sentence (Months)',
        headerAlign: 'center',
        valueGetter: ({ row }: GridValueGetterParams<number>) =>
            row.sentenceSet?.map(({ suspendedMonths }) => suspendedMonths || 0)[0],
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
        groupable: false,
        width: 144,
    },
    {
        field: 'chargeCount',
        type: 'number',
        sortable: false,
        headerName: 'Num. Charges',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<number>) => row.indictmentCharges.length,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'guiltyCount',
        type: 'number',
        sortable: false,
        headerName: 'Num. Guilty Charges',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<number>) => row.indictmentCharges.filter(({ guiltyVerdict }: IndictmentCharge) => !!guiltyVerdict).length,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'convicted',
        type: 'boolean',
        headerName: 'Convicted',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }: GridValueGetterParams<boolean>) => some(row.indictmentCharges, 'guiltyVerdict'),
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'aggravatingFactors',
        headerName: 'Aggravating Factors',
        headerAlign: 'left',
        width: 200,
        groupable: false,
        renderCell: ({ row }: GridRenderCellParams<string>) => <FactorCell factorsString={row.aggravatingFactors} />
    },
    {
        field: 'mitigatingFactors',
        headerName: 'Mitigating Factors',
        headerAlign: 'left',
        width: 200,
        groupable: false,
        renderCell: ({ row }: GridRenderCellParams<string>) => <FactorCell factorsString={row.mitigatingFactors} />
    },
];

export default columns;
