import React, { ReactNode, useMemo, useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { omit, pick } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    AdvancedFiltersManager,
    AdvancedFiltersRef,
    RawAdvancedFilter,
} from '../../../../components/AdvancedFiltersManager';
import { CustomDialog } from '../../../../components/CustomDialog';
import { CREATE_FILTER_CONFIG, UPDATE_FILTER_CONFIG } from '../../../../api/apollo/mutation';
import { StyledForm } from '../../../../components/StyledForm';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuth } from '../../../../api/auth';
import { GET_COMMODITIES, GET_SPECIES, GET_FILTER, GET_LEGISLATION, GET_HEARING_STAGES } from '../../../../api/apollo/query';
import GET_LEGISLATION_PARTS from '../../../../api/apollo/query/GetLegislationParts';
import { CourtCaseFilters } from '../../../../types/CourtCaseFilters';
import { FilterComponent } from './components/FilterComponent';
import { FullScreenLoader } from '../../../../components/Loader/FullScreenLoader';
import { CourtCasesSetup } from './setups/courtCasesSetup';
import {
    AdvancedFilterContext,
    deleteFilter,
    getFilter,
    initialValue,
    saveFilter,
    StorageConfigInterface,
} from './AdvancedFilterContext';
import styles from './AdvancedFilter.module.css';

export interface AdvancedFilterProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onFilters: (filters: CourtCaseFilters) => void;
}

export type FilterFunction = () => CourtCaseFilters;

class AdvancedFilter {
    pipe(filters: FilterFunction[]): [CourtCaseFilters, string[]] {
        let newFilter: CourtCaseFilters = {};

        const order: string[] = [];
        filters.forEach((filter) => {
            const parts = filter();
            order.push(...Object.keys(parts));
            newFilter = { ...newFilter, ...parts };
        });

        return [newFilter, order];
    }
}

export const AdvancedFilterComponent = ({ open, onOpenChange, onFilters }: AdvancedFilterProps) => {
    const { user } = useAuth();
    const { data } = useQuery(GET_COMMODITIES, { skip: !user });
    const { data: speciesData } = useQuery(GET_SPECIES, { skip: !user });
    const { data: legislationData } = useQuery(GET_LEGISLATION, { skip: !user });
    const { data: legislationPartsData } = useQuery(GET_LEGISLATION_PARTS, { skip: !user });
    const { data: hearingStageData } = useQuery(GET_HEARING_STAGES, { skip: !user });
    const [createFilter, { loading: createFilterLoading }] = useMutation(CREATE_FILTER_CONFIG);
    const [updateFilter] = useMutation(UPDATE_FILTER_CONFIG);
    const [getFilterConfig] = useLazyQuery(GET_FILTER);

    const trackedSpecies = useMemo(() => {
        if (speciesData?.species) {
            return speciesData.species.map((s) =>
                pick(s, ['id', 'commonName', 'kingdom', 'scientificName'])
            );
        }

    }, [speciesData]);

    const commodityOptions = useMemo(() => {
        if (data?.commodities) {
            return data.commodities.map(({ id, species, detail, state }) => ({
                id,
                species: omit(species, '__typename'),
                detail: omit(detail, '__typename'),
                state: omit(state, '__typename'),
            }));
        }
        return [];
    }, [data]);

    const [loading, setLoading] = useState<boolean>(false);
    const {
        control,
        formState: { errors },
        setError,
        handleSubmit,
    } = useForm({
        defaultValues: {
            title: '',
        },
    });
    const freshStart = useRef<boolean>(true);
    const advancedFiltersManagerRef = useRef<AdvancedFiltersRef>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(open);
    const [openCreateAdvancedFilterDialog, setOpenCreateAdvancedFilterDialog] = useState<boolean>(false);
    const [advancedFilter] = useState<AdvancedFilter>(new AdvancedFilter());
    const [filters, setFilters] = useState<Record<string, FilterFunction | undefined>>({});
    const [filterComponents, setFilterComponents] = useState<[string, ReactNode][]>([]);
    const [rawFilters, setRawFilters] = useState<RawAdvancedFilter[]>([]);
    const [currentAdvancedFilter, setCurrentAdvancedFilter] = useState<RawAdvancedFilter | undefined>();
    const [defaultFiltersValue, setDefaultFiltersValue] = useState<CourtCaseFilters>({});

    useEffect(() => {
        if (freshStart.current || !dialogOpen) {
            return;
        }
        const [config, order] = advancedFilter.pipe(
            Object.values(filters).filter((item) => item !== undefined) as FilterFunction[]
        );

        saveFilter({ config, order }, currentAdvancedFilter);
    }, [freshStart.current, filters, currentAdvancedFilter, dialogOpen]);

    useEffect(() => {
        const data = getFilter();

        if (!data) {
            return;
        }

        if (data?.config) {
            const promise = new Promise<void>((resolve) => {
                if (data?.filter) {
                    const res = getFilterConfig({ variables: { id: data.filter.id } });
                    if (!res || !data?.filter?.id || !data?.filter?.title) {
                        resolve();
                        return;
                    }
                    res.then(({ data: { advancedCourtCaseFilterConfig } }) => {
                        setCurrentAdvancedFilter({
                            id: data?.filter?.id as string,
                            title: data?.filter?.title as string,
                            config: advancedCourtCaseFilterConfig.config || '{config: {}, order: []}',
                        });

                        const parsedData = JSON.parse(advancedCourtCaseFilterConfig.config) as StorageConfigInterface;
                        setDefaultFiltersValue(parsedData?.config || {});
                    }).finally(() => {
                        resolve();
                    });
                } else {
                    setDefaultFiltersValue(data.config?.config || {});
                    resolve();
                }
            });
            promise.finally(() => {
                setFilterComponents((data?.config?.order || []).map((field) => getFilterComponent(field)));
                freshStart.current = false;
            });
        }
    }, []);

    useEffect(() => {
        setDialogOpen(open);
    }, [open]);

    const onSubmit = () => {
        const [result] = advancedFilter.pipe(
            Object.values(filters).filter((item) => item !== undefined) as FilterFunction[]
        );

        onFilters(result);
        handleClose();
    };

    const handleClose = () => {
        onOpenChange(false);
        setDialogOpen(false);
    };

    const getAvailableFields = () => {
        return Object.keys(CourtCasesSetup).filter((field) => {
            return !Object.keys(filters).includes(field) && CourtCasesSetup[field].getComponent !== undefined;
        });
    };

    const getFilterComponent = (defaultField: string): [string, ReactNode] => {
        let currentField = defaultField;
        const id = uuidv4();

        const onFilterFunction = (prevField: string, newField: string, filterFunction?: FilterFunction) => {
            setFilters((prev) => {
                const cloned = { ...prev };
                delete cloned[prevField];
                cloned[newField] = filterFunction;

                currentField = newField;

                return cloned;
            });
        };

        const onRemove = () => {
            setFilters((prev) => {
                const cloned = { ...prev };
                delete cloned[currentField];

                return cloned;
            });
            setFilterComponents((prev) => {
                const cloned = [...prev];
                const index = cloned.findIndex(([itemId]) => itemId === id);

                cloned.splice(index, 1);
                return cloned;
            });
        };

        return [
            id,
            <FilterComponent
                onRemove={onRemove}
                field={currentField as keyof CourtCaseFilters}
                onFilterFunction={onFilterFunction}
            />,
        ];
    };

    const addFilter = () => {
        freshStart.current = false;
        const availableFields = getAvailableFields();
        let currentField = availableFields[0];

        setFilterComponents(filterComponents.concat([getFilterComponent(currentField)]));
    };

    const reset = () => {
        setFilters({});
        if (currentAdvancedFilter) {
            try {
                const result = JSON.parse(currentAdvancedFilter?.config || '{config: {}, order: []}');
                const order = result?.order || [];
                setFilterComponents(order.map((field) => getFilterComponent(field)));
            } catch {}
        } else {
            setFilterComponents([]);
            setCurrentAdvancedFilter(undefined);
        }
        deleteFilter();
    };

    const onRawFilters = (filters: RawAdvancedFilter[]) => {
        setRawFilters(filters);
    };

    const openAdvancedFilterManager = async () => {
        if (!advancedFiltersManagerRef.current) {
            return;
        }

        const chosenFilter = await advancedFiltersManagerRef.current.open();
        if (!chosenFilter) {
            return;
        }

        try {
            const data = JSON.parse(chosenFilter?.config || '{config: {}, order: []}') as StorageConfigInterface;
            setCurrentAdvancedFilter(chosenFilter);
            setFilters({});

            const order = data?.order || [];

            setFilterComponents(order.map((field) => getFilterComponent(field)));
            setDefaultFiltersValue(data?.config || {});
        } catch {
            return;
        }
    };

    const openAdvancedFilterCreateDialog = () => {
        setOpenCreateAdvancedFilterDialog(true);
    };

    const createAdvancedFilter = async (data) => {
        if (!data.title) {
            return;
        }
        if (rawFilters.find((item) => item.title === data.title)) {
            setError('title', { type: 'duplicate', message: 'Filter with current name already defined' });
            return;
        } else {
            setError('title', { type: 'duplicate', message: undefined });
        }

        const [result, order] = advancedFilter.pipe(
            Object.values(filters).filter((item) => item !== undefined) as FilterFunction[]
        );
        const config = JSON.stringify({ config: result, order });
        const { data: filterData } = await createFilter({
            variables: {
                filterConfig: {
                    title: data.title,
                    config,
                },
            },
        });

        if (advancedFiltersManagerRef?.current?.addFilterConfig) {
            advancedFiltersManagerRef.current.addFilterConfig(
                filterData.createFilterConfig.filterConfig.id,
                data.title
            );
        }
        setCurrentAdvancedFilter({ id: filterData.createFilterConfig.filterConfig.id, config, title: data.title });
        setOpenCreateAdvancedFilterDialog(false);
    };

    const updateAdvancedFilter = async () => {
        if (!currentAdvancedFilter) {
            return;
        }

        const [result, order] = advancedFilter.pipe(
            Object.values(filters).filter((item) => item !== undefined) as FilterFunction[]
        );

        const config = JSON.stringify({ config: result, order });
        setLoading(true);
        await updateFilter({
            variables: {
                filterConfig: {
                    title: currentAdvancedFilter.title,
                    id: currentAdvancedFilter.id,
                    config,
                },
            },
        });
        setCurrentAdvancedFilter({ ...currentAdvancedFilter, config });
        setLoading(false);
    };

    const currentConfig = useMemo(() => {
        const [config, order] = advancedFilter.pipe(
            Object.values(filters).filter((item) => item !== undefined) as FilterFunction[]
        );
        return JSON.stringify({ config, order });
    }, [filters]);

    const closeFilter = () => {
        setFilters({});
        setFilterComponents([]);
        setCurrentAdvancedFilter(undefined);
        deleteFilter();
        setDefaultFiltersValue({});
    };

    /*
        Using styles to hide and show a dialog box should prevent the contents of the dialog box from being re-rendered
        It's bad practice, but in this case I can't find another way
     */
    return (
        <>
            <CustomDialog
                open={openCreateAdvancedFilterDialog}
                onClose={() => {
                    setOpenCreateAdvancedFilterDialog(false);
                }}
                title="Create filter"
                loading={createFilterLoading}
            >
                <StyledForm onSubmit={handleSubmit(createAdvancedFilter)}>
                    <Controller
                        name="title"
                        control={control}
                        rules={{
                            required: 'Title is required.',
                        }}
                        render={({ field }) => <TextField placeholder="Enter filter name" {...field} />}
                    />
                    {Object.keys(errors).filter((key) => !!errors[key].message).length !== 0 &&
                        Object.keys(errors).map((key) => (
                            <FormHelperText key={key} color="error">
                                {errors[key]?.message as string}
                            </FormHelperText>
                        ))}
                    <Button type="submit" color="secondary" variant="contained">
                        Create
                    </Button>
                </StyledForm>
            </CustomDialog>
            <Dialog sx={{ display: dialogOpen ? 'block' : 'none' }} open={true} onClose={handleClose} fullWidth>
                {loading && <FullScreenLoader className={styles.loader} />}
                <IconButton className={styles.closeIcon} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle className={styles.titleWrapper}>
                    <div className={styles.dialogTitleBlock}>
                        <FilterListIcon className={styles.titleIcon} />
                        <Typography variant="h6" className={styles.title}>
                            Advanced Filter
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={styles.advancedFilterTools}>
                        <div className={styles.advancedFilterTitleBlock}>
                            {currentAdvancedFilter && (
                                <>
                                    <Tooltip title="Disable this filter.">
                                        <IconButton onClick={closeFilter}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Typography variant="h6" className={styles.title}>
                                        {currentAdvancedFilter.title}
                                    </Typography>
                                </>
                            )}
                        </div>
                        <div className={styles.toolsBlock}>
                            {currentAdvancedFilter && currentConfig !== currentAdvancedFilter?.config && (
                                <IconButton onClick={updateAdvancedFilter}>
                                    <BackupOutlinedIcon />
                                </IconButton>
                            )}
                            <IconButton onClick={openAdvancedFilterCreateDialog}>
                                <AddIcon />
                            </IconButton>
                            <IconButton onClick={openAdvancedFilterManager}>
                                <TuneIcon />
                            </IconButton>
                        </div>
                    </div>
                    <AdvancedFilterContext.Provider
                        value={{
                            ...initialValue,
                            user,
                            commodityOptions,
                            availableFields: getAvailableFields(),
                            legislation: legislationData?.legislation || [],
                            legislationParts: legislationPartsData?.legislationParts || [],
                            hearingStageOptions: hearingStageData
                                ? hearingStageData.hearingStages.map(({ stage }: { stage: string }) => stage)
                                : [],
                            defaultFiltersValue,
                            currentFilter: currentAdvancedFilter,
                            trackedSpecies,
                        }}
                    >
                        <>
                            {filterComponents.length === 0 ? (
                                <Typography align="center" variant="subtitle1" className={styles.noFiltersSelected}>
                                    No Filters selected
                                </Typography>
                            ) : (
                                <div className={styles.filtersHeader}>
                                    <Typography align="center" variant="subtitle1" className={styles.filtersHeaderItem}>
                                        Field
                                    </Typography>
                                    <Typography align="center" variant="subtitle1" className={styles.filtersHeaderItem}>
                                        Operator
                                    </Typography>
                                    <Typography align="center" variant="subtitle1" className={styles.filtersHeaderItem}>
                                        Value
                                    </Typography>
                                    <div className={styles.filtersHeaderSpace} />
                                </div>
                            )}
                            {filterComponents.map(([id, el]) => (
                                <React.Fragment key={id}>{el}</React.Fragment>
                            ))}
                            {Object.keys(filters).length !== Object.keys(CourtCasesSetup).length && (
                                <Button onClick={addFilter} className={styles.addButton}>
                                    +
                                </Button>
                            )}
                            <div className={styles.footer}>
                                <Button onClick={reset} variant="outlined" color="primary">
                                    Reset
                                </Button>
                                <Button onClick={onSubmit} variant="contained" color="secondary">
                                    Apply
                                </Button>
                            </div>
                        </>
                        <AdvancedFiltersManager onFilters={onRawFilters} ref={advancedFiltersManagerRef} />
                    </AdvancedFilterContext.Provider>
                </DialogContent>
            </Dialog>
        </>
    );
};
