import { gql } from '@apollo/client';

const GET_COURT_CASES = gql`
    query courtCases($filters: CourtCaseFilters) {
        courtCases(filters: $filters) {
            id
            docket
            name
            courts {
                id
                name
                location {
                    administrativeLevel1 {
                        id
                        name
                    }
                }
            }
            commodities {
                id
                name
            }
            caseDurationDays
            isConcluded
            isConviction
            defenseAttorneyCount
            firstEvent {
                id
                date
                stage
            }
            latestEvent {
                id
                date
                stage
            }
        }
    }
`;

export default GET_COURT_CASES;