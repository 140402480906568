import { useEffect, useState } from 'react';
import { Login as LoginComponent } from '@c4ads/c4blocks';
import { LoginCredentials } from '@c4ads/c4auth';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAuth } from '../../api/auth';
import { Loader } from '../../components/Loader';
import { openSnackBar } from '../../components/Snackbar';
import backgroundImg from '../../static/img/login_background.jpg';
import loginStyles from './Login.module.css';

export default function Login() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isAuthenticated, login } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboards');
        }
    }, [isAuthenticated, navigate]);
    const handleLogin = (data: LoginCredentials) => {
        setIsLoading(true);
        login(data, (e) => {
            setIsLoading(false);
            if (!e.error) {
                navigate('/dashboards');
            } else {
                if (e?.response?.status === 400) {
                    openSnackBar({
                        message: 'Wrong email or password.',
                        type: 'error',
                    });
                }
            }
        });
    };

    const resetPassword = () => {
        navigate('/reset-password');
    };

    return (
        <>
            <LoginComponent
                title="Welcome back!"
                subtitle="Log in with your credentials to get started"
                backgroundImageSrc={backgroundImg}
                onLogin={handleLogin}
                onResetPassword={resetPassword}
            />
            {isLoading && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={loginStyles.loader}
                >
                    <Grid item>
                        <Loader />
                    </Grid>
                </Grid>
            )}
        </>
    );
}
