import { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useGridApiRef, GridEventListener, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import { Commodity, Location, Officer, Seizure } from '../../../../types';
import GET_SEIZURES from '../../../../api/apollo/query/GetSeizures';
import TableToolbar from '../TableToolbar';
import { ApprehensionTeam } from '../../../../types/ApprehensionTeam';
import columns from './columns';
import { useAuth } from '../../../../api/auth';

export interface SeizureDataGrid {
    id: string;
    rowId: string;
    docket: string | null;
    date: string | null;
    location: string | null;
    district: string | null;
    arrestingOfficers?: Officer[];
    apprehensionTeams?: ApprehensionTeam[];
    seizedCommodityId: string;
    seizedCommodityCommodity?: Commodity;
    seizedCommodityProtectionLevel: string | null;
    seizedCommodityCount: number | null;
    seizedCommodityWeightKg: number | null;
    seizedCommodityValue: number | null;
    origin: string | null;
    destination: string | null;
}

const getLocationString = (value: Location | null) => {
    let result = '';
    if (value?.name) {
        result += value?.name;
    }

    if (value?.administrativeLevel1) {
        result += result.length > 0 ? `, ${value?.administrativeLevel1.name}` : value?.administrativeLevel1.name;
    }
    return result;
};

const prepareSeizures = (seizures: Seizure[]) => {
    const result: SeizureDataGrid[] = [];

    seizures.forEach((item) => {
        item.seizedCommodities.forEach((commodity) => {
            result.push({
                id: item.id,
                rowId: `${item.id} - ${commodity.id}`,
                docket: item.courtCases ? item.courtCases[0]?.docket : null,
                date: item.date,
                location: item.location?.name || null,
                district: item.location?.administrativeLevel1?.name || null,
                arrestingOfficers: item.arrestingOfficers,
                apprehensionTeams: item.apprehensionTeams,
                seizedCommodityId: commodity.id,
                seizedCommodityCommodity: commodity.commodity,
                seizedCommodityProtectionLevel: commodity.protectionLevel,
                seizedCommodityCount: commodity.count,
                seizedCommodityWeightKg: commodity.weightKg,
                seizedCommodityValue: commodity.value,
                origin: commodity.origin,
                destination: commodity.destination,
            });
        });
    });
    return result;
};

export const Seizures = ({
    shrinkTitle,
    hideHeaders,
    showHeaders,
}: {
    shrinkTitle: boolean;
    hideHeaders: () => void;
    showHeaders: () => void;
}) => {
    const { user } = useAuth();
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<SeizureDataGrid[]>([]);

    const { loading } = useQuery(GET_SEIZURES, {
        onCompleted: ({ seizures }: { seizures: Seizure[] }) => setRows(prepareSeizures(seizures)),
    });

    useEffect(() => {
        const handleEvent: GridEventListener<'rowsScroll'> = (params) => {
            params.top > 5 ? hideHeaders() : showHeaders();
        };

        apiRef.current.subscribeEvent('rowsScroll', handleEvent);
    }, [apiRef, hideHeaders, showHeaders]);

    const getAggregationPosition = useCallback((groupNode) => (groupNode == null ? 'footer' : 'inline'), []);

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            aggregation: {
                model: {
                    seizedCommodityCount: 'sum',
                    seizedCommodityWeightKg: 'sum',
                    seizedCommodityValue: 'sum',
                },
            },
        },
    });

    return (
        <CustomDataGrid
            apiRef={apiRef}
            getRowId={(row) => row.rowId}
            columns={columns}
            rows={rows}
            checkboxSelection={false}
            aggregationRowsScope="all"
            getAggregationPosition={getAggregationPosition}
            rowGroupingColumnMode="single"
            loading={loading}
            hideFooterSelectedRowCount
            hideFooterPagination
            components={{ Toolbar: TableToolbar }}
            componentsProps={{
                toolbar: {
                    title: 'Seizures',
                    showTitle: shrinkTitle,
                    csvExportEnabled: user?.permissions?.map(({codename}) => codename).includes("can_export_tables"),
                }
            }}
            initialState={initialState}
            experimentalFeatures={{ aggregation: true }}
        />
    );
};

export default Seizures;